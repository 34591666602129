import { Create } from 'react-admin';
import LocationForm from '../../Common/Locations/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateLocation = (props: CreateProps) => (
  <Create {...props} redirect={'list'}>
    <LocationForm />
  </Create>
);

export default CreateLocation;
