import {
  Datagrid,
  List,
  FunctionField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from "react-bootstrap";
import * as React from 'react';

const HistoryStroller = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [show, setShow] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cleanValues = {
    0: 'no',
    1: 'yes'
  };
  const defectValues = {
    0: 'None',
    1: 'Handle is broken',
    2: 'Wheel stuck'
  };
  const renderCleanValue = (record: any) => (
    `${record?.reportValues
      .filter((item: any) => {
        return item?.type === '0';
      })
      .map((item: any) => {
        return `${cleanValues[`${item?.value}`]}`;
      })
    }`
  );
  const renderDefectsValue = (record: any) => (
    `${record?.reportValues
      .filter((item: any) => {
        return item?.type === '1';
      })
      .map((item: any) => {
        return `${defectValues[`${item?.value}`]}`;
      })
    }`
  );
  const renderComplaintsValue = (record: any) => (
    `${record?.reportValues
      .filter((item: any) => {
        return item?.type === '2';
      })
      .map((item: any) => {
        return `${item?.value}`;
      })
    }`
  );

  if (!record) return null;

  return (
    <>
      <List
        resource={'admin/conditions'}
        filter={{ stroller: record.id }}
        exporter={false}
        actions={false}
        title={<>{translate('app.show.strollers.tabs.maintenance_history.label')}</>}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <FunctionField
            source={'createdAt'}
            render={(record: any) => `${new Date(record.createdAt).toLocaleString('en-GB')}`}
            label={'app.show.strollers.tabs.maintenance_history.unlock_time'}
          />
          <FunctionField
            label={'app.show.strollers.tabs.maintenance_history.baby_carriage_clean'}
            source="reportValues"
            render={renderCleanValue}
          />
          <FunctionField
            label={'app.show.strollers.tabs.maintenance_history.something_broken'}
            source="reportValues"
            render={renderDefectsValue}
          />
          <FunctionField
            label={'app.show.strollers.tabs.maintenance_history.complaints'}
            source="reportValues"
            render={renderComplaintsValue}
          />

          <FunctionField
            label={'app.show.strollers.tabs.maintenance_history.pictures'}
            render={(record: any) => {
              return record?.reportMedia.map((item: any) => {
                return (
                  <Image
                    onClick={() => {
                      setUrl(item.filePath);
                      handleShow()
                    }}
                    width={'auto'}
                    height={'50px'}
                    style={{padding: '5px'}}
                    src={`${process.env.REACT_APP_ENTRYPOINT}/images/media/${item.filePath}`}
                  />
                );
              });
            }}
          />
        </Datagrid>
      </List>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Image
            width={'100%'}
            height={'auto'}
            src={`${process.env.REACT_APP_ENTRYPOINT}/images/media/${url}`} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoryStroller;
