import { Grid, Typography } from '@mui/material';

import {
  TextInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useLocaleState,
  useNotify,
  useTranslate, required, minLength, maxLength,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = (props: ToolbarProps) => {
  const { reset } = useFormContext();
  const notify = useNotify();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            window.scrollTo(0, 0);
            notify('app.form.administrator.edit.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

const FormAdmin = (props: CreatePaymentFormProps) => {
  const { isCreate } = props;
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {isCreate ? translate('app.form.administrator.title_create') : translate('app.form.administrator.title_edit')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.administrator.general.name')}*
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          {isCreate ? (
            <TextInput
              source={'firstName'}
              label={'app.form.administrator.general.name'}
              validate={[required(), minLength(2), maxLength(255)]}
              sx={{ width: '100%' }}
              name={'firstName'}
            />
          ) : (
            <TextInput
              source={'firstName'}
              validate={[required(), minLength(2), maxLength(255)]}
              sx={{ width: '100%' }}
              label={'app.form.administrator.general.name'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.administrator.general.surname')}*
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          {isCreate ? (
            <TextInput
              source={'lastName'}
              label={'app.form.administrator.general.surname'}
              validate={[required(), minLength(2), maxLength(255)]}
              sx={{ width: '100%' }}
              name={'lastName'}
            />
          ) : (
            <TextInput
              source={'lastName'}
              validate={[required(), minLength(2), maxLength(255)]}
              sx={{ width: '100%' }}
              label={'app.form.administrator.general.surname'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.administrator.general.email')}*
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          {isCreate ? (
            <TextInput
              source={'email'}
              label={'app.form.administrator.general.email'}
              validate={[required()]}
              sx={{ width: '100%' }}
              name={'email'}
            />
          ) : (
            <TextInput
              source={'email'}
              validate={[required()]}
              sx={{ width: '100%' }}
              label={'app.form.administrator.general.email'}
            />
          )}
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default FormAdmin;

export interface CreatePaymentFormProps {
  isCreate?: boolean;
}
