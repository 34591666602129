import { Grid, Typography } from '@mui/material';
import {
  maxLength,
  minLength, NumberInput, BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  TextInput,
  useLocaleState,
  useTranslate,
} from 'react-admin';

const WorkspaceForm = (props: CreateWorkspaceFormProps) => {
  const { isCreate } = props;
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.name')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <TextInput
            source={'name'}
            label={'app.form.workspaces.general.name'}
            validate={[required(), minLength(2), maxLength(100)]}
            sx={{ width: '100%' }}
            name={'name'}
          />
        ) : (
          <TextInput
            source={'name'}
            label={'app.form.workspaces.general.name'}
            validate={[required(), minLength(2), maxLength(100)]}
            sx={{ width: '100%' }}
            name={'name'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.description')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <TextInput
            source={'description'}
            label={'app.form.workspaces.general.description'}
            sx={{ width: '100%' }}
            name={'description'}
            fullWidth
            multiline
          />
        ) : (
          <TextInput
            source={'description'}
            label={'app.form.workspaces.general.description'}
            sx={{ width: '100%' }}
            name={'description'}
            fullWidth
            multiline
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.location')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <ReferenceArrayInput
          source="locationIds"
          reference={'admin/locations'}
          name={'locationIds'}
          validate={[required()]}
          perPage={300}
        >
          <AutocompleteArrayInput
            sx={{ width: '100%' }}
            label={'app.form.workspaces.general.location'}
            optionText={`translations.name.${locale}`}
          />
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.max_location')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <NumberInput
            source={'maxLocations'}
            label={'app.form.workspaces.general.max_location'}
            sx={{ width: '100%' }}
            validate={[required()]}
            name={'maxLocations'}
          />
        ) : (
          <NumberInput
            source={'maxLocations'}
            label={'app.form.workspaces.general.max_location'}
            sx={{ width: '100%' }}
            validate={[required()]}
            name={'maxLocations'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.admin_rights')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <BooleanInput
            source={'hasAdminRights'}
            sx={{ width: '100%' }}
            label={''}
            name={'hasAdminRights'}
          />
        ) : (
          <BooleanInput
            source={'hasAdminRights'}
            sx={{ width: '100%' }}
            label={''}
            name={'hasAdminRights'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.heatmap')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <BooleanInput
            source={'allowHeatMap'}
            sx={{ width: '100%' }}
            label={''}
            name={'allowHeatMap'}
          />
        ) : (
          <BooleanInput
            source={'allowHeatMap'}
            sx={{ width: '100%' }}
            label={''}
            name={'allowHeatMap'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.general.walking_lines')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        {isCreate ? (
          <BooleanInput
            source={'allowWalkLine'}
            sx={{ width: '100%' }}
            label={''}
            name={'allowWalkLine'}
          />
        ) : (
          <BooleanInput
            source={'allowWalkLine'}
            sx={{ width: '100%' }}
            label={''}
            name={'allowWalkLine'}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default WorkspaceForm;

export interface CreateWorkspaceFormProps {
  isCreate?: boolean;
}
