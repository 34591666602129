import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  useLocaleState,
  useTranslate,
  WrapperField,
  ReferenceInput,
} from 'react-admin';
import { ListProps } from 'ra-ui-materialui/src/list/List';
import TranslatedField from '../../Common/TranslatedField';
import * as React from "react";

const PaymentList = (props: ListProps) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const postFilters = [
    <ReferenceInput
      source="location"
      reference={'admin/locations'}
      perPage={300}
      name={'location'} alwaysOn
    >
      <AutocompleteInput
        sx={{ width: '300px' }}
        label={'app.list.payments.location'}
        optionText={`translations.name.${locale}`}
      />
    </ReferenceInput>,
  ];

  return (
    <>
      <List
        {...props}
        actions={false}
        filters={postFilters}
        title={<>{translate('app.menu.payments')}</>}
      >
        <Datagrid
          bulkActionButtons={false}
          size={'medium'}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={`mollieTransactionId`}
            label={'app.list.payments.transaction_id'}
            sortable={false}
          />
          <DateField
            source={`paidAt`}
            label={'app.list.payments.date'}
            locales={'en-GB'}
            sortable={false}
          />
          <TextField
            source={`location.translations.${locale}.name`}
            label={'app.list.payments.location'}
            sortable={false}
          />
          <TextField
            source={`user.firstName`}
            label={'app.list.payments.user'}
            sortable={false}
          />
          <TextField
            source={`amount`}
            label={'app.list.payments.amount'}
            sortable={false}
          />
          <TextField
            source={`paid`}
            label={'app.list.payments.paid'}
            sortable={false}
          />
          <TranslatedField
            source={'status'}
            label={'app.list.payments.status'}
            sortable={true}
            prefix={'app.common.payments.status'}
          />
          <WrapperField>
            <EditButton />
          </WrapperField>
        </Datagrid>
      </List>
    </>
  );
};

export default PaymentList;
