import { Edit, useRecordContext, useTranslate } from 'react-admin';

import PaymentForm from '../../Common/Payments/Form';
import { EditProps } from 'ra-ui-materialui/src/types';

const EditTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const titleText = record ? record.code ?? undefined : undefined;

  return (
    <span>
      {translate('app.edit.payments.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const EditPayment = (props: EditProps) => {
  return (
    <Edit
      {...props}
      actions={false}
      title={<EditTitle />}
      mutationMode={'pessimistic'}
    >
      <PaymentForm />
    </Edit>
  );
};

export default EditPayment;
