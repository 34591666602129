import {
  Show,
  SimpleShowLayout, TabbedShowLayout,
  TextField,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { SimpleShowLayoutProps } from 'ra-ui-materialui/src/detail/SimpleShowLayout';
import TranslatedField from '../../Common/TranslatedField';
import HistoryStroller from "../Strollers/Tabs/HistoryStroller";
import StateStroller from "../Strollers/Tabs/StateStroller";

const ShowTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const titleText = record ? record.code ?? undefined : undefined;

  return (
    <span>
      {translate('app.show.strollers.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const ShowStroller = (props: SimpleShowLayoutProps) => {
  const [locale] = useLocaleState();

  return (
    <Show title={<ShowTitle />}>
      <SimpleShowLayout {...props}>
        <TextField
          source={'code'}
          variant={'h5'}
          label={'app.show.strollers.code'}
        />
        <TextField
          source={'deviceIosId'}
          variant={'h5'}
          label={'app.show.strollers.device_ios_id'}
        />
        <TextField
          source={'deviceAndroidId'}
          variant={'h5'}
          label={'app.show.strollers.device_android_id'}
        />
        <TextField
          source={'bluetoothDevicePassword'}
          variant={'h5'}
          label={'app.show.strollers.device_password'}
        />
        <TextField
          source={'url'}
          sx={{ width: '100%' }}
          label={'app.show.strollers.url'}
          variant={'h6'}
        />
        <TextField
          source={`location.translations.${locale}.name`}
          label={'app.show.strollers.location'}
        />
        <TranslatedField
          source={'status'}
          label={'app.show.strollers.status'}
          prefix={'app.common.strollers.status'}
        />
        <TextField
          source={'statusMessage'}
          label={'app.show.strollers.status_message'}
        />
      </SimpleShowLayout>
      <TabbedShowLayout {...props}>
        <TabbedShowLayout.Tab label="app.show.strollers.tabs.state.label">
          <StateStroller />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="app.show.strollers.tabs.maintenance_history.label">
          <HistoryStroller />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ShowStroller;
