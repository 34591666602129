import {
  Confirm,
  Datagrid,
  DateField,
  List,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  WrapperField,
  TopToolbar,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useState } from 'react';
import {Button, IconButton} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import * as React from "react";
import {Link} from "react-router-dom";
import ContentAdd from "@mui/icons-material/Add";

const CancelCouponButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.cancelCoupon(record, dataProvider).then((data: any) => {
      if (data.id) {
        notify('app.show.locations.tabs.coupons.actions.cancelled', {
          type: 'success',
          messageArgs: { smart_count: 1 },
        });
        refresh();
      } else {
        notify(data.detail, {
          type: 'error',
          messageArgs: { smart_count: 1 },
        });
        refresh();
      }
    }).catch(() => {
      notify('Error', {
        type: 'error',
        messageArgs: { smart_count: 1 },
      });
    })
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };
  let modalContent = translate('app.show.locations.tabs.coupons.actions.modal.content');

  return (
    <>
      <IconButton
        aria-label="Example"
        onClick={handleClick}
        disabled={isLoading}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={'app.show.locations.tabs.coupons.actions.modal.title'}
        content={modalContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const EditCouponButton = () => {
  const record = useRecordContext();

  return (
    <IconButton
      component={Link}
      to={{
        pathname: '/admin/coupons/' + encodeURIComponent(record.id),
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

const LocationCoupons = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <>
      <TopToolbar>
        <Button
          startIcon={<ContentAdd />}
          component={Link}
          to={{
            pathname: '/admin/coupons/create',
          }}
          state={{ record: { 'location': record, 'active': true } }}
        >
          {translate('app.show.locations.tabs.coupons.actions.create')}
        </Button>
      </TopToolbar>
      <List
        resource={'admin/coupons'}
        exporter={false}
        filter={{ location: record.id }}
        actions={false}
        title={<>{translate('app.show.locations.tabs.coupons.title')}</>}
        hasCreate={false}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={'code'}
            label={'app.show.locations.tabs.coupons.table.coupon_code'}
          />
          <TextField
            source={'description'}
            label={'app.show.locations.tabs.coupons.table.description'}
          />
          <TextField
            source={'discountPercentage'}
            sortable={false}
            label={'app.show.locations.tabs.coupons.table.discount'}
          />
          <DateField
            showDate
            source={'availableAt'}
            locales={'en-GB'}
            sortable={false}
            label={'app.show.locations.tabs.coupons.table.start_date'}
          />
          <DateField
            showDate
            source={'expiresAt'}
            sortable={false}
            locales={'en-GB'}
            label={'app.show.locations.tabs.coupons.table.end_date'}
          />
          <WrapperField label={'app.show.locations.tabs.coupons.table.action'}>
            <div style={{display: 'flex'}}>
              <CancelCouponButton />
              <EditCouponButton />
            </div>
          </WrapperField>
        </Datagrid>
      </List>
    </>
  );
};

export default LocationCoupons;
