import en from 'ra-language-english';
import { merge } from 'lodash';

const englishMessages = {
  'ra-supabase': {
    auth: {
      sign_in_with: 'Sign in with %{provider}',
    },
  },
  ra: {
    page: {
      empty: 'No results found',
    },
  },
  app: {
    menu: {
      locations: 'Strollo points (Locations)',
      reservations: 'Reservations',
      media: 'Media',
      strollers: 'Strollers',
      payments: 'Payments',
      beacon: 'Beacon',
      geofence: 'Geofence',
      workspaces: 'Workspaces',
      dashboard: 'Dashboard Platform',
      administrator: 'Administrators',
    },
    edit: {
      locations: {
        title: 'Edit location',
      },
      strollers: {
        title: 'Edit stroller',
      },
      payments: {
        title: 'Edit payment',
      },
      beaconGroup: {
        title: 'Edit beacon group',
      },
      geofence: {
        title: 'Edit geofence',
      }
    },
    common: {
      strollers: {
        status: {
          available: 'Available',
          defective: 'Defective',
          maintenance: 'In maintenance',
          not_returned: 'Not returned',
          checked_in: 'Unlocked',
          pause: 'Paused',
        },
      },
      payments: {
        status: {
          0: 'Unknown',
          1: 'Pending',
          2: 'Paid',
          3: 'Cancelled',
          4: 'Failed',
          5: 'Expired',
        },
      },
    },
    show: {
      strollers: {
        title: 'Stroller details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        device_ios_id: 'Device iOS ID',
        device_android_id: 'Device Android ID',
        device_password: 'Device password',
        url: 'QR Code link',
        tabs: {
          state: {
            label: 'State',
            reported: 'Reported',
            state: 'State',
            latitude: 'Latitude',
            longitude: 'Longitude',
          },
          maintenance_history: {
            label: 'Maintenance history',
            unlock_time: 'Unlock time',
            baby_carriage_clean: 'Baby carriage clean?',
            something_broken: 'Something broken?',
            complaints: 'Complaints',
            pictures: 'Pictures',
          },
        },
      },
      workspaces: {
        title: 'Workspaces details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        url: 'QR Code link',
        label: 'Reservations',
        table: {
          name: 'Name',
          action: 'Action',
          description: 'Description',
          email: 'Email',
          phone: 'Phone',
        },
        actions: {
          cancel: 'Cancel workspace',
          create: 'Create workspace',
          cancelled: 'Cancelled',
          modal: {
            title: 'Cancel workspace',
            content: `Are you sure you want to cancel this workspace?`,
          },
        },
      },
      locations: {
        title: 'Location details',
        tabs: {
          details: {
            label: 'Details',
            total_strollers: 'Total number of strollers',
            number_of_available_strollers: 'Number of available strollers',
            stroller_reservation_pool:
              'Max. number of strollers that can be reserved',
            number_of_strollers_available_for_registration:
              'Current number of strollers available for registration',
            number_of_locked_strollers_today:
              'Current number of locked strollers',
            address: 'Location Strollo point',
            translations: {
              name: 'Name',
              description: 'Description',
              intro: 'Intro',
              customer_name: 'Customer Name',
              target_group_info: 'For whom?',
              opening_hours_info: 'Opening hours',
              accessibility_info: 'Availability',
              additional_info: 'Any questions?',
              rental_process_info: 'How does it work?',
              contact_info: 'Contact',
              opening_hours: 'Opening Hours',
              closing_hours_info: 'Closing hours Strollo point',
              price_info: 'Price',
            },
          },
          strollers: {
            label: 'Strollers',
            title: ' - Strollers',
            table: {
              code: 'Code',
              status: 'Status',
              status_message: 'Status message',
            },
          },
          reservations: {
            label: 'Reservations',
            title: ' - Reservations',
            table: {
              name: 'By',
              date: 'Date',
              status: 'Status',
              email: 'Email',
              phone: 'Phone',
              strollers: 'Strollers',
            },
            actions: {
              cancel: 'Cancel reservation',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel reservation',
                content: `Are you sure you want to cancel %{person}'s reservation on %{date}?`,
              },
            },
          },
          coupons: {
            label: 'Coupons',
            title: ' - Coupons',
            table: {
              coupon_code: 'Coupon code',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel coupon',
              create: 'Add coupon code',
              edit: 'Edit coupon code',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel coupon',
                content: `Are you sure you want to cancel this coupon?`,
              },
            },
          },
          opening_hours: {
            label: 'Opening hours & pricing',
            title: ' - Opening hours & pricing',
            table: {
              name: 'Name',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel opening hours & pricing',
              create: 'Add opening hours & pricing',
              edit: 'Edit opening hours & pricing',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel opening hours & pricing',
                content: `Are you sure you want to cancel this opening hours & pricing?`,
              },
            },
          },
          walk_lines: {
            label: 'Walk lines',
          },
          heatmap: {
            label: 'Heatmap',
          }
        },
      },
      administrators: {
        title: 'Administrator details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        url: 'QR Code link',
        label: 'Reservations',
        table: {
          name: 'Name',
          action: 'Action',
          surname: 'Surname',
          workspaces: 'Workspaces',
          phone: 'Phone',
        },
        actions: {
          cancel: 'Cancel Administrator',
          create: 'Create Administrator',
          cancelled: 'Cancelled',
          modal: {
            title: 'Cancel Administrator',
            content: `Are you sure you want to cancel this Administrator?`,
          },
        },
      },
    },
    form: {
      strollers: {
        create: {
          success: 'Stroller created successfully',
        },
        general: {
          labels: {
            stroller_info: 'Stroller info',
            stroller_status: 'Stroller status',
          },
          code: 'Code',
          url: 'QR Code link',
          status: {
            label: 'Status',
          },
          status_message: 'Status message',
          device_ios_id: 'Device iOS ID',
          device_android_id: 'Device Android ID',
          device_password: 'Device password',
          location: 'Linked to Strollo point (optional)',
        },
      },
      locations: {
        general: {
          labels: {
            image: 'Image',
          },
          address: 'Location Strollo point',
          label: 'General',
          stroller_reservation_pool: 'Stroller reservation pool',
          publish_status: {
            label: 'Status',
            published: 'Published',
            draft: 'Draft',
            offline: 'Offline',
          },
          image: 'Image',
          mollie_id: 'Mollie id',
        },
        notes: {
          label: 'Notes',
          alert: {
            title: 'Notice!',
            description: 'This information is for internal use only.',
          },
        },
        translations: {
          label: 'Translations',
          name: 'Name',
          description: 'Description',
          intro: 'Intro',
          customer_name: 'Customer Name',
          target_group_info: 'For whom?',
          opening_hours_info: 'Opening hours',
          accessibility_info: 'Availability',
          additional_info: 'Any questions?',
          rental_process_info: 'How does it work?',
          closing_hours_info: 'Closing hours Strollo point',
          contact_info: 'Contact',
          opening_hours: 'Opening Hours',
          price_info: 'Price',
        },
        create: {
          success: 'Location saved successfully',
        },
      },
      coupons: {
        create: {
          success: 'Coupon saved successfully',
        },
        edit: {
          success: 'Coupon updated successfully',
        },
        general: {
          code: 'Discount code',
          description: 'Description',
          discount: 'Discount',
          start_date: 'Start date',
          end_date: 'End date',
          action: 'Action',
          status_message: 'Status message',
        },
      },
      opening_hours: {
        create: {
          success: 'Opening hours saved successfully',
        },
        edit: {
          success: 'Opening hours saved successfully',
        },
        tab : {
          active_period: 'Active period',
          weekday: 'Weekday',
          pricing: 'Pricing',
          start_time: 'Start time',
          end_time: 'End time',
          hour_price: 'Openings hours & Pricing',
          periods: {
            title: 'Periods',
            actions: {
              cancelled: 'Cancelled',
              created: 'Period added successfully',
              modal: {
                title: 'Cancel period',
                content: `Are you sure you want to cancel this period?`,
              }
            }
          },
        },
        general: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          name: 'Name',
          price_per_hour: 'Price per hour',
          price_per_additional_hour: 'Price per additional hour',
          price_per_day: 'Price per day',
          price_per_4_hours: 'Price per 4 hours',
        },
      },
      periods: {
        create: {
          success: 'Period created successfully',
        },
        edit: {
          success: 'Period created successfully',
        },
        general: {
          start_date: 'Start date',
          end_date: 'End date',
        },
      },
      payments: {
        edit: {
          success: 'Payment saved successfully',
        },
        general: {
          labels: {
            payment_info: 'Payment info',
            payment_status: 'Payment status',
          },
          user: 'User',
          stroller: 'Stroller',
          amount: 'Amount',
          paid: 'Paid',
          created_at: 'Created at',
          status: {
            label: 'Status',
          },
          status_message: 'Status message',
          location: 'Linked to Strollo point (optional)',
        },
      },
      beaconGroup: {
        beaconGroup: {
          title: 'Beacon group',
          name: 'Name',
          workspace: 'Workspace',
        },
        beacon: {
          title: 'Beacons',
          name: 'Name',
          uuid: 'Beacon UUID',
        },
      },
      geofence: {
        title: 'Geofence',
        name: 'Name',
        workspace: 'Workspace',
        latitude: 'Latitude',
        longitude: 'Longitude',
        add_new_geofence: 'Add new geofence',
      },
      workspaces: {
        create: {
          success: 'Workspace saved successfully',
        },
        tab: {
          title: 'Create Workspace',
          title_edit: 'Edit Workspace',
          workspace: 'Workspace',
          administrator: 'Administrator',
        },
        general: {
          labels: {
            workspace_info: 'Workspace info',
            stroller_status: 'Stroller status',
          },
          name: 'Name',
          description: 'Description',
          location: 'Location',
          max_location: 'Max Location',
          admin_rights: 'Admin rights',
          heatmap: 'Heatmap',
          users: 'Users',
          walking_lines: 'Walking lines',
        },
      },
      administrator: {
        title: "Administrator",
        title_edit: "Edit Administrator",
        title_create: "Create Administrator",
        edit: {
          success: 'Administrator saved successfully',
        },
        general: {
          labels: {
            administrator_info: 'Administrator info',
          },
          name: 'Name',
          surname: 'Surname',
          email: 'E-mail',
        },
      },
    },
    list: {
      media: {
        image: 'Image',
        title: 'Name',
        actions: {
          create: 'Add new image',
        },
      },
      dashboard: {
        title: 'Dashboard',
        error: 'Error',
        error_des: 'You are not having access to this site. Please contact the admin for support.',
        users_nb: 'Number of users',
        reservations_nb: 'Number of reservations',
        succesfull_payments_nb: 'Number of successful payments',
        locations_nb: 'Number of locations',
        cancellations_nb: 'Number of cancellations',
        open_payments_nb: 'Number of open payments',
        filter: {
          last_week: 'Last week',
          last_month: 'Last month',
          last_year: 'Last year',
          last_three_months: 'Last three months',
        }
      },
      strollers: {
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        actions: {
          create: 'Add new stroller',
          import: 'Import',
          successful_import: 'Import successfully',
        },
      },
      locations: {
        name: 'Customer (Strollo point)',
        translations: 'Translations',
        image: 'Image',
        available_strollers: '# Available',
        total_strollers: '# Total',
        actions: {
          create: 'Create new B2B customer',
        },
      },
      payments: {
        transaction_id: 'Transaction ID',
        date: 'Date',
        status: 'Status',
        location: 'Location',
        amount: 'Amount',
        paid: 'Paid',
        user: 'User',
        actions: {
          create: 'Add new stroller',
        }
      },
      beaconGroup: {
        name: 'Name',
        actions: {
          create: 'Create new beacon group',
        },
      },
      geofence: {
        geofence: 'Geofence',
        name: 'Name',
        actions: {
          create: 'Create new geofence',
        },
      }
    },
  },
};

const messages = merge(en, englishMessages);

export default messages;
