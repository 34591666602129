export const validUpdateFields = [
  'id',
  'location',
  'code',
  'status',
  'statusMessage',
  'deviceIosId',
  'deviceAndroidId',
  'bluetoothDevicePassword',
];
