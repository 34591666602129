import { Grid, Typography } from '@mui/material';
import {
  useTranslate,
  ToolbarProps,
  useNotify,
  SaveButton,
  DateInput,
  useDataProvider,
  useRefresh,
  useRecordContext,
  SaveContextProvider
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const PeriodForm = (props: CreatePeriodFormProps) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const save = (data:any) => {
    dataProvider.createPeriod(record, data, dataProvider).then((data: any) => {
      if (data.id) {
        notify('app.form.opening_hours.tab.periods.actions.created', {
          type: 'success',
          messageArgs: { smart_count: 1 },
        });
      } else {
        notify(data.detail, {
          type: 'error',
          messageArgs: { smart_count: 1 },
        });
      }
      refresh();
      reset();
    }).catch(() => {
      notify('Error', {
        type: 'error',
        messageArgs: { smart_count: 1 },
      });
    });
  }
  const saving = false;
  const mutationMode = "pessimistic";
  const CustomToolbar = (props: ToolbarProps) => {
    return (
      <SaveButton
        label="Add"
        type={'button'}
      />
    );
  };

  return (
    <SaveContextProvider value={{ save, saving, mutationMode }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.periods.general.start_date')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DateInput
            source={'startDate'}
            label={'app.form.periods.general.start_date'}
            sx={{ width: '100%' }}
            name={'startDate'}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.periods.general.end_date')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DateInput
            source={'endDate'}
            label={'app.form.periods.general.end_date'}
            sx={{ width: '100%' }}
            name={'endDate'}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2} style={{paddingTop: '35px'}}>
          <CustomToolbar />
        </Grid>
      </Grid>
    </SaveContextProvider>
  );
};

export default PeriodForm;

export interface CreatePeriodFormProps {
  isCreate?: boolean;
}
