export const validUpdateFields = [
  'id',
  'location',
  'code',
  'active',
  'description',
  'discountPercentage',
  'availableAt',
  'expiresAt',
];
