import {
  Datagrid,
  List,
  useRecordContext,
  useTranslate, TextField,
} from 'react-admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';

const StateStroller = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <>
      <List
        resource={'admin/states'}
        filter={{ stroller: record.id }}
        exporter={false}
        actions={false}
        title={<>{translate('app.show.strollers.tabs.state.label')}</>}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={'currentReportedAt'}
            sortable={false}
            label={'app.show.strollers.tabs.state.reported'}
          />
          <TextField
            source={'currentStatus'}
            label={'app.show.strollers.tabs.state.state'}
          />
          <TextField
            source={'trackingLat'}
            label={'app.show.strollers.tabs.state.latitude'}
          />
          <TextField
            source={'trackingLng'}
            label={'app.show.strollers.tabs.state.longitude'}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default StateStroller;
