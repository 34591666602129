import fr from 'ra-language-french';
import { merge } from 'lodash';

const frenchMessages = {
  'ra-supabase': {
    auth: {
      sign_in_with: 'Se connecter avec %{provider}',
    },
  },
  ra: {
    page: {
      empty: 'Aucun résultat trouvé',
    },
  },
  app: {
    menu: {
      locations: 'Points Strollo (Lieux)',
      reservations: 'Réservations',
      media: 'Média',
      strollers: 'Poussettes',
      beacon: 'Beacon',
      geofence: 'Geofence',
      payments: 'Payments',
      workspaces: 'Workspaces',
      dashboard: 'Dashboard Platform',
      administrator: 'Administrators',
    },
    edit: {
      locations: {
        title: "Modifier l'emplacement",
      },
      strollers: {
        title: 'Modifier la poussette',
      },
      payments: {
        title: 'Edit payment',
      },
      beaconGroup: {
        title: 'Edit beacon group',
      },
      geofence: {
        title: 'Edit geofence',
      }
    },
    common: {
      strollers: {
        status: {
          available: 'Disponible',
          defective: 'Défectueux',
          maintenance: 'En maintenance',
          not_returned: 'Non retourné',
          checked_in: 'Déverrouillé',
          pause: 'Paused',
        },
      },
      payments: {
        status: {
          0: 'Unknown',
          1: 'Pending',
          2: 'Paid',
          3: 'Cancelled',
          4: 'Failed',
          5: 'Expired',
        },
      },
    },
    show: {
      strollers: {
        title: 'Détails de la poussette',
        code: 'Code',
        status: 'Statut',
        status_message: 'Message de statut',
        location: 'Emplacement',
        device_ios_id: 'Device iOS ID',
        device_android_id: 'Device Android ID',
        device_password: 'Device password',
        url: 'Lien QR code',
        tabs: {
          state: {
            label: 'State',
            reported: 'Reported',
            state: 'State',
            latitude: 'Latitude',
            longitude: 'Longitude',
          },
          maintenance_history: {
            label: 'Maintenance history',
            unlock_time: 'Unlock time',
            baby_carriage_clean: 'Baby carriage clean?',
            something_broken: 'Something broken?',
            complaints: 'Complaints',
            pictures: 'Pictures',
          },
        },
      },
      workspaces: {
        title: 'Workspaces details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        url: 'QR Code link',
        label: 'Reservations',
        table: {
          name: 'Name',
          action: 'Action',
          description: 'Description',
          email: 'Email',
          phone: 'Phone',
        },
        actions: {
          cancel: 'Cancel workspace',
          create: 'Create workspace',
          cancelled: 'Cancelled',
          modal: {
            title: 'Cancel workspace',
            content: `Are you sure you want to cancel this workspace?`,
          },
        },
      },
      locations: {
        title: "Détails de l'emplacement",
        tabs: {
          details: {
            label: 'Détails',
            total_strollers: 'Nombre total de poussettes',
            number_of_available_strollers: 'Nombre de poussettes disponibles',
            stroller_reservation_pool:
              'Max. nombre de poussettes pouvant être réservées',
            number_of_strollers_available_for_registration:
              "Nombre actuel de poussettes disponibles pour l'enregistrement",
            number_of_locked_strollers_today:
              'Nombre actuel de poussettes verrouillées',
            address: 'Adresse du point Strollo',
            translations: {
              name: 'Nom',
              description: 'Description',
              intro: 'Intro',
              customer_name: 'Nom du client',
              target_group_info: 'Pour qui?',
              opening_hours_info: "Heures d'ouverture",
              accessibility_info: "Disponibilité d'accès",
              additional_info: 'Des questions?',
              rental_process_info: 'Comment ça marche?',
              contact_info: 'Coordonnées',
              opening_hours: "Heures d'ouverture",
              closing_hours_info: 'Heures de fermeture',
              price_info: 'Prix',
            },
          },
          strollers: {
            label: 'Poussettes',
            title: ' - Poussettes',
            table: {
              code: 'Code',
              status: 'Statut',
              status_message: 'Message de statut',
            },
          },
          reservations: {
            label: 'Réservations',
            title: ' - Réservations',
            table: {
              name: 'Par',
              date: 'Date',
              status: 'Statut',
              email: 'E-mail',
              phone: 'Téléphone',
              strollers: 'Strollers',
            },
            actions: {
              cancel: 'Annuler la réservation',
              cancelled: 'Annulé',
              modal: {
                title: 'Annuler la réservation',
                content: `Êtes-vous sûr de vouloir annuler la réservation de %{person} le %{date}?`,
              },
            },
          },
          coupons: {
            label: 'Coupons',
            title: ' - Coupons',
            table: {
              coupon_code: 'Coupon code',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel coupon',
              create: 'Add coupon code',
              edit: 'Edit coupon code',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel coupon',
                content: `Are you sure you want to cancel this coupon?`,
              },
            },
          },
          opening_hours: {
            label: 'Opening hours & pricing',
            title: ' - Opening hours & pricing',
            table: {
              name: 'Name',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel opening hours & pricing',
              create: 'Add opening hours & pricing',
              edit: 'Edit opening hours & pricing',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel opening hours & pricing',
                content: `Are you sure you want to cancel this opening hours & pricing?`,
              },
            },
          },
          walk_lines: {
            label: 'Walk lines',
          },
          heatmap: {
            label: 'Heatmap',
          }
        },
      },
    },
    form: {
      strollers: {
        create: {
          success: 'Poussette créée avec succès',
        },
        general: {
          labels: {
            stroller_info: 'Informations sur la poussette',
            stroller_status: 'Statut de la poussette',
          },
          code: 'Code',
          url: 'Lien QR code',
          device_ios_id: 'Device iOS ID',
          device_android_id: 'Device Android ID',
          device_password: 'Device password',
          status: {
            label: 'Statut',
          },
          status_message: 'Message de statut',
          location: 'Lié à un point Strollo (optionnel)',
        },
      },
      locations: {
        general: {
          labels: {
            image: 'Image',
          },
          address: 'Adresse du point Strollo',
          label: 'Général',
          stroller_reservation_pool: 'Piscine de réservation de poussettes',
          publish_status: {
            label: 'Statut',
            published: 'Publié',
            draft: 'Brouillon',
            offline: 'Hors ligne',
          },
          image: 'Image',
          mollie_id: 'Mollie id',
        },
        notes: {
          label: 'Notes',
          alert: {
            title: 'Attention !',
            description:
              'Ces informations sont destinées à un usage interne uniquement.',
          },
        },
        translations: {
          label: 'Traductions',
          name: 'Nom',
          description: 'Description',
          intro: 'Intro',
          customer_name: 'Nom du client',
          target_group_info: 'Pour qui?',
          opening_hours_info: "Horaires d'ouverture",
          accessibility_info: "Disponibilité d'accès",
          additional_info: 'Des questions?',
          rental_process_info: 'Comment ça marche?',
          closing_hours_info: 'Heures de fermeture',
          contact_info: 'Coordonnées',
          opening_hours: "Horaires d'ouverture",
          price_info: 'Prix',
        },
        create: {
          success: 'Location saved successfully',
        },
      },
      coupons: {
        create: {
          success: 'Coupon saved successfully',
        },
        edit: {
          success: 'Coupon updated successfully',
        },
        general: {
          code: 'Discount code',
          description: 'Description',
          discount: 'Discount',
          start_date: 'Start date',
          end_date: 'End date',
          action: 'Action',
          status_message: 'Status message',
        },
      },
      opening_hours: {
        create: {
          success: 'Opening hours saved successfully',
        },
        edit: {
          success: 'Opening hours saved successfully',
        },
        tab : {
          active_period: 'Active period',
          weekday: 'Weekday',
          pricing: 'Pricing',
          start_time: 'Start time',
          end_time: 'End time',
          hour_price: 'Openings hours & Pricing',
          periods: {
            title: 'Periods',
            actions: {
              cancelled: 'Cancelled',
              created: 'Period added successfully',
              modal: {
                title: 'Cancel period',
                content: `Are you sure you want to cancel this period?`,
              }
            }
          },
        },
        general: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          name: 'Name',
          price_per_hour: 'Price per hour',
          price_per_additional_hour: 'Price per additional hour',
          price_per_day: 'Price per day',
          price_per_4_hours: 'Price per 4 hours',
        },
      },
      periods: {
        create: {
          success: 'Period created successfully',
        },
        edit: {
          success: 'Period created successfully',
        },
        general: {
          start_date: 'Start date',
          end_date: 'End date',
        },
      },
      payments: {
        edit: {
          success: 'Payment saved successfully',
        },
        general: {
          labels: {
            payment_info: 'Payment info',
            payment_status: 'Payment status',
          },
          user: 'User',
          stroller: 'Stroller',
          amount: 'Amount',
          paid: 'Paid',
          created_at: 'Created at',
          status: {
            label: 'Status',
          },
          status_message: 'Status message',
          location: 'Linked to Strollo point (optional)',
        },
      },
      beaconGroup: {
        beaconGroup: {
          title: 'Beacon group',
          name: 'Name',
          workspace: 'Workspace',
        },
        beacon: {
          title: 'Beacons',
          name: 'Name',
          uuid: 'Beacon UUID',
        },
      },
      geofence: {
        title: 'Geofence',
        name: 'Name',
        workspace: 'Workspace',
        latitude: 'Latitude',
        longitude: 'Longitude',
        add_new_geofence: 'Add new geofence',
      },
      workspaces: {
        create: {
          success: 'Workspace saved successfully',
        },
        tab: {
          title: 'Create Workspace',
          workspace: 'Workspace',
          administrator: 'Administrator',
        },
        general: {
          labels: {
            workspace_info: 'Workspace info',
            stroller_status: 'Stroller status',
          },
          name: 'Name',
          description: 'Description',
          location: 'Location',
          max_location: 'Max Location',
          admin_rights: 'Admin rights',
          heatmap: 'Heatmap',
          users: 'Users',
          walking_lines: 'Walking lines',
        },
      },
      administrator: {
        title: "Administrator",
        edit: {
          success: 'Administrator saved successfully',
        },
        general: {
          labels: {
            administrator_info: 'Administrator info',
          },
          name: 'Name',
          surname: 'Surname',
          email: 'E-mail',
        },
      },
    },
    list: {
      media: {
        image: 'Image',
        title: 'Nom',
        actions: {
          create: 'Ajouter une nouvelle image',
        },
      },
      dashboard: {
        title: 'Dashboard',
        error: 'Error',
        error_des: 'You are not having access to this site. Please contact the admin for support.',
        users_nb: 'Number of users',
        reservations_nb: 'Number of reservations',
        succesfull_payments_nb: 'Number of successful payments',
        locations_nb: 'Number of locations',
        cancellations_nb: 'Number of cancellations',
        open_payments_nb: 'Number of open payments',
        filter: {
          last_week: 'Last week',
          last_month: 'Last month',
          last_year: 'Last year',
          last_three_months: 'Last three months',
        }
      },
      strollers: {
        code: 'Code',
        status: 'Statut',
        status_message: 'Message de statut',
        location: 'Emplacement',
        actions: {
          create: 'Ajouter une nouvelle poussette',
        },
      },
      locations: {
        name: 'Client (Point Strollo)',
        translations: 'Traductions',
        image: 'Image',
        available_strollers: '# Disponible',
        total_strollers: '# Total',
        actions: {
          create: 'Créer un nouveau client B2B',
        },
      },
      payments: {
        transaction_id: 'Transaction ID',
        date: 'Date',
        status: 'Status',
        location: 'Location',
        amount: 'Amount',
        paid: 'Paid',
        user: 'User',
        actions: {
          create: 'Add new stroller',
        }
      },
      beaconGroup: {
        name: 'Name',
        actions: {
          create: 'Create new beacon group',
        },
      },
      geofence: {
        geofence: 'Geofence',
        name: 'Name',
        actions: {
          create: 'Create new geofence',
        },
      }
    },
  },
};

const messages = merge(fr, frenchMessages);

export default messages;
