import {
  TextField,
  CreateButton,
  Datagrid,
  List,
  ShowButton,
  TopToolbar,
  WrapperField,
  ImageField,
  DeleteWithConfirmButton,
} from 'react-admin';
import { ListProps } from 'ra-ui-materialui/src/list/List';

const MediaList = (props: ListProps) => {
  const ListActions = () => (
    <TopToolbar>
      <CreateButton label={'app.list.media.actions.create'} />
    </TopToolbar>
  );

  return (
    <List {...props} actions={<ListActions />}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={'show'}
        size={'medium'}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <ImageField source={'contentUrl'} label={'app.list.media.image'} />
        <TextField source={'title'} label={'app.list.media.title'} />
        <WrapperField>
          <ShowButton />
          <DeleteWithConfirmButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default MediaList;
