import {
  DateInput,
  useRecordContext,
  useRefresh,
  useGetList,
} from 'react-admin';
import * as React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {GoogleMap, HeatmapLayer, useJsApiLoader} from '@react-google-maps/api';
import { useRef } from "react";
import { REACT_APP_MAPS_API_KEY } from '../../../../constants';
import {Grid} from "@mui/material";

const LocationHeatMap = () => {
  const record = useRecordContext();
  const mapRef = useRef();
  const date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const firstDayData = new Date(y, m, 2);
  const lastDayData = new Date(y, m + 1, 0);
  const refresh = useRefresh();
  //default form values
  const form = useForm({
    defaultValues: {
      createdAt: {
        after: firstDayData.toISOString().split('T')[0],
        before: lastDayData.toISOString().split('T')[0],
      }
    },
  });

  //get data map
  let walkLineDatas : any = [];
  const { data } = useGetList(
    'admin/walklines',
    {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        location: record.id,
        createdAt: {
          before : form.getValues().createdAt.before + 'T23:59:59+00:00',
          after : form.getValues().createdAt.after + 'T00:00:00+00:00',
        }
      }
    }
  );

  //map data for line walk
  data && data.map((walkLine:any) => {
    const trackings = JSON.parse(walkLine?.tracking);
    const latLng = trackings.map((tracking:any) => {
      if (tracking?.lat && tracking?.lng) {
        return new google.maps.LatLng(
          parseFloat(tracking?.lat),
          parseFloat(tracking?.lng)
        );
      }
    });

    walkLineDatas = walkLineDatas.concat(latLng);
    return walkLineDatas;
  });

  const { isLoaded: googleMapIsLoaded } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_MAPS_API_KEY,
    libraries: ['visualization'],
  });

  const onFilter = () => {
    refresh();
  };

  const customGradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)',
  ];

  if (!record) return null;

  return googleMapIsLoaded && walkLineDatas ? (
    <>
      <FormProvider {...form}>
        <form>
          <DateInput
            source={'createdAt[after]'}
            label={'app.form.coupons.general.start_date'}
            onChange={() => onFilter()}
            style={{ marginRight: 10 }}
          />
          <DateInput
            source={'createdAt[before]'}
            label={'app.form.coupons.general.end_date'}
            onChange={() => onFilter()}
          />
        </form>
      </FormProvider>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <GoogleMap
            options={{ zoomControl: false, mapTypeControl: false, streetViewControl: false }}
            zoom={15}
            center={walkLineDatas.length > 0 ? {lat: walkLineDatas[0]?.lat(), lng: walkLineDatas[0]?.lng()} : {lat:0, lng:0}}
            mapContainerStyle={{ width: "100%", height: "400px" }}>
            {walkLineDatas && (
              <HeatmapLayer data={walkLineDatas} gradient={customGradient} radius={20} opacity={1}/>
            )}
          </GoogleMap>
        </Grid>
      </Grid>
    </>
  ) : <></>;
};

export default LocationHeatMap;
