import { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/OpeningHour';

export const openingHoursLifecycleCallbacks = {
  resource: 'admin/opening_hours',
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
    if (get(data.location, '@id', false)) {
      set(data, 'location', get(data.location, '@id'));
    } else {
      data.location = null;
    }
    const locale = 'vi';
    const options = { hour: 'numeric', minute: 'numeric', hour12: false }
    set(data, 'mondayStartTime', new Date(data.mondayStartTime).toLocaleTimeString(locale, options));
    set(data, 'mondayEndTime', new Date(data.mondayEndTime).toLocaleTimeString(locale, options));
    set(data, 'tuesdayStartTime', new Date(data.tuesdayStartTime).toLocaleTimeString(locale, options));
    set(data, 'tuesdayEndTime', new Date(data.tuesdayEndTime).toLocaleTimeString(locale, options));
    set(data, 'wednesdayStartTime', new Date(data.wednesdayStartTime).toLocaleTimeString(locale, options));
    set(data, 'wednesdayEndTime', new Date(data.wednesdayEndTime).toLocaleTimeString(locale, options));
    set(data, 'thursdayStartTime', new Date(data.thursdayStartTime).toLocaleTimeString(locale, options));
    set(data, 'thursdayEndTime', new Date(data.thursdayEndTime).toLocaleTimeString(locale, options));
    set(data, 'fridayStartTime', new Date(data.fridayStartTime).toLocaleTimeString(locale, options));
    set(data, 'fridayEndTime', new Date(data.fridayEndTime).toLocaleTimeString(locale, options));
    set(data, 'saturdayStartTime', new Date(data.saturdayStartTime).toLocaleTimeString(locale, options));
    set(data, 'saturdayEndTime', new Date(data.saturdayEndTime).toLocaleTimeString(locale, options));
    set(data, 'sundayStartTime', new Date(data.sundayStartTime).toLocaleTimeString(locale, options));
    set(data, 'sundayEndTime', new Date(data.sundayEndTime).toLocaleTimeString(locale, options));

    return data;
  },

  afterRead: async (data, dataProvider) => {
    if (data.mondayStartTime && data.mondayStartTime != null
      && data.mondayEndTime && data.mondayEndTime != null
      && data.tuesdayStartTime && data.tuesdayStartTime != null
      && data.tuesdayEndTime && data.tuesdayEndTime != null
      && data.wednesdayStartTime && data.wednesdayStartTime != null
      && data.wednesdayEndTime && data.wednesdayEndTime != null
      && data.thursdayStartTime && data.thursdayStartTime != null
      && data.thursdayEndTime && data.thursdayEndTime != null
      && data.fridayStartTime && data.fridayStartTime != null
      && data.fridayEndTime && data.fridayEndTime != null
      && data.saturdayStartTime && data.saturdayStartTime != null
      && data.saturdayEndTime && data.saturdayEndTime != null
      && data.sundayStartTime && data.sundayStartTime != null
      && data.sundayEndTime && data.sundayEndTime != null) {
      data.mondayStartTime = data.mondayStartTime.substring(0, 10) + ' ' +data.mondayStartTime.substring(11, 16);
      data.mondayEndTime = data.mondayEndTime.substring(0, 10) + ' ' +data.mondayEndTime.substring(11, 16);
      data.tuesdayStartTime = data.tuesdayStartTime.substring(0, 10) + ' ' +data.tuesdayStartTime.substring(11, 16);
      data.tuesdayEndTime = data.tuesdayEndTime.substring(0, 10) + ' ' +data.tuesdayEndTime.substring(11, 16);
      data.wednesdayStartTime = data.wednesdayStartTime.substring(0, 10) + ' ' +data.wednesdayStartTime.substring(11, 16);
      data.wednesdayEndTime = data.wednesdayEndTime.substring(0, 10) + ' ' +data.wednesdayEndTime.substring(11, 16);
      data.thursdayStartTime = data.thursdayStartTime.substring(0, 10) + ' ' +data.thursdayStartTime.substring(11, 16);
      data.thursdayEndTime = data.thursdayEndTime.substring(0, 10) + ' ' +data.thursdayEndTime.substring(11, 16);
      data.fridayStartTime = data.fridayStartTime.substring(0, 10) + ' ' +data.fridayStartTime.substring(11, 16);
      data.fridayEndTime = data.fridayEndTime.substring(0, 10) + ' ' +data.fridayEndTime.substring(11, 16);
      data.saturdayStartTime = data.saturdayStartTime.substring(0, 10) + ' ' +data.saturdayStartTime.substring(11, 16);
      data.saturdayEndTime = data.saturdayEndTime.substring(0, 10) + ' ' +data.saturdayEndTime.substring(11, 16);
      data.sundayStartTime = data.sundayStartTime.substring(0, 10) + ' ' +data.sundayStartTime.substring(11, 16);
      data.sundayEndTime = data.sundayEndTime.substring(0, 10) + ' ' +data.sundayEndTime.substring(11, 16);
    }

    return data;
  },

  afterSave: async (data, dataProvider) => {
    if (data.mondayStartTime) {
      data.mondayStartTime = data.mondayStartTime.substring(0, 10) + ' ' +data.mondayStartTime.substring(11, 16);
      data.mondayEndTime = data.mondayEndTime.substring(0, 10) + ' ' +data.mondayEndTime.substring(11, 16);
      data.tuesdayStartTime = data.tuesdayStartTime.substring(0, 10) + ' ' +data.tuesdayStartTime.substring(11, 16);
      data.tuesdayEndTime = data.tuesdayEndTime.substring(0, 10) + ' ' +data.tuesdayEndTime.substring(11, 16);
      data.wednesdayStartTime = data.wednesdayStartTime.substring(0, 10) + ' ' +data.wednesdayStartTime.substring(11, 16);
      data.wednesdayEndTime = data.wednesdayEndTime.substring(0, 10) + ' ' +data.wednesdayEndTime.substring(11, 16);
      data.thursdayStartTime = data.thursdayStartTime.substring(0, 10) + ' ' +data.thursdayStartTime.substring(11, 16);
      data.thursdayEndTime = data.thursdayEndTime.substring(0, 10) + ' ' +data.thursdayEndTime.substring(11, 16);
      data.fridayStartTime = data.fridayStartTime.substring(0, 10) + ' ' +data.fridayStartTime.substring(11, 16);
      data.fridayEndTime = data.fridayEndTime.substring(0, 10) + ' ' +data.fridayEndTime.substring(11, 16);
      data.saturdayStartTime = data.saturdayStartTime.substring(0, 10) + ' ' +data.saturdayStartTime.substring(11, 16);
      data.saturdayEndTime = data.saturdayEndTime.substring(0, 10) + ' ' +data.saturdayEndTime.substring(11, 16);
      data.sundayStartTime = data.sundayStartTime.substring(0, 10) + ' ' +data.sundayStartTime.substring(11, 16);
      data.sundayEndTime = data.sundayEndTime.substring(0, 10) + ' ' +data.sundayEndTime.substring(11, 16);
    }

    return data;
  }
};
