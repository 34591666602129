import { Grid, Typography } from '@mui/material';

import {
  AutocompleteInput,
  maxLength,
  minLength,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useLocaleState,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = (props: ToolbarProps) => {
  const { reset } = useFormContext();
  const notify = useNotify();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            window.scrollTo(0, 0);
            notify('app.form.strollers.create.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

const StrollerForm = (props: CreateStrollerFormProps) => {
  const { isCreate } = props;
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {translate('app.form.strollers.general.labels.stroller_info')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          {isCreate ? (
            <TextInput
              source={'code'}
              label={'app.form.strollers.general.code'}
              validate={[required(), minLength(6), maxLength(6)]}
              sx={{ width: '100%' }}
              name={'code'}
            />
          ) : (
            <TextInput
              source={'url'}
              sx={{ width: '100%' }}
              label={'app.form.strollers.general.url'}
              disabled={true}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ReferenceInput
            source="location.@id"
            reference={'admin/locations'}
            name={'location'}
            perPage={300}
          >
            <AutocompleteInput
              sx={{ width: '100%' }}
              label={'app.form.strollers.general.location'}
              optionText={`translations.name.${locale}`}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {translate('app.form.strollers.general.labels.stroller_status')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <RadioButtonGroupInput
            source="status"
            label={'app.form.strollers.general.status.label'}
            validate={required()}
            sx={{ width: '100%' }}
            defaultValue={'available'}
            choices={[
              {
                id: 'available',
                name: 'app.common.strollers.status.available',
              },
              {
                id: 'defective',
                name: 'app.common.strollers.status.defective',
              },
              {
                id: 'maintenance',
                name: 'app.common.strollers.status.maintenance',
              },
              {
                id: 'not_returned',
                name: 'app.common.strollers.status.not_returned',
              },
              {
                id: 'checked_in',
                name: 'app.common.strollers.status.checked_in',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <TextInput
            name={'deviceIosId'}
            source={'deviceIosId'}
            multiline
            label={'app.form.strollers.general.device_ios_id'}
            sx={{ width: '100%' }}
          />
          <TextInput
            name={'deviceAndroidId'}
            source={'deviceAndroidId'}
            multiline
            label={'app.form.strollers.general.device_android_id'}
            sx={{ width: '100%' }}
          />
          <TextInput
            name={'bluetoothDevicePassword'}
            source={'bluetoothDevicePassword'}
            multiline
            label={'app.form.strollers.general.device_password'}
            sx={{ width: '100%' }}
          />
          <TextInput
            name={'statusMessage'}
            source={'statusMessage'}
            multiline
            label={'app.form.strollers.general.status_message'}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default StrollerForm;

export interface CreateStrollerFormProps {
  isCreate?: boolean;
}
