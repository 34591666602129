import {
  NumberField,
  RichTextField,
  Show,
  TabbedShowLayout,
  TranslatableFields,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import LocationStrollers from './Tabs/LocationStrollers';
import LocationReservations from './Tabs/LocationReservations';
import LocationCoupons from './Tabs/LocationCoupons';
import LocationOpeningHours from './Tabs/LocationOpeningHours';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { TabbedShowLayoutProps } from 'ra-ui-materialui/src/detail/TabbedShowLayout';
import { ReactNode } from 'react';
import LocationWalkLines from "./Tabs/LocationWalkLines";
import LocationHeatMap from "./Tabs/LocationHeatmap";

const ShowTitle = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const titleText = record
    ? record.translations.name
      ? record.translations.name[locale]
      : undefined ?? undefined
    : undefined;

  return (
    <span>
      {translate('app.show.locations.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const LocationResourceLayout = (props: { children: ReactNode }) => {
  const { children } = props;
  const record = useRecordContext();

  return (
    <Card>
      {record ? (
        record.image ? (
          <CardMedia
            image={record.image.contentUrl}
            sx={{ height: '16EM', objectFit: 'contain' }}
          />
        ) : null
      ) : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {ShowTitle()}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

const ShowLocation = (props: TabbedShowLayoutProps) => {
  const translate = useTranslate();
  const allowHeatMap = localStorage.getItem('allowHeatMap');
  const allowWalkLine = localStorage.getItem('allowWalkLine');
  return (
    <>
      <Show title={<ShowTitle />}>
        <LocationResourceLayout>
          <TabbedShowLayout {...props}>
            <TabbedShowLayout.Tab label="app.show.locations.tabs.details.label">
              <NumberField
                source={'totalStrollers'}
                label={'app.show.locations.tabs.details.total_strollers'}
              />

              <NumberField
                source={'strollerReservationPool'}
                label={
                  'app.show.locations.tabs.details.stroller_reservation_pool'
                }
              />

              <NumberField
                source={'numberOfAvailableStrollersForRegistration'}
                label={
                  'app.show.locations.tabs.details.number_of_strollers_available_for_registration'
                }
              />

              <RichTextField
                source={'address'}
                label={translate('app.show.locations.tabs.details.address')}
              />

              <TranslatableFields locales={['nl', 'en', 'fr']}>
                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.name'
                  )}
                </Typography>
                <RichTextField source={'translations.name'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.customer_name'
                  )}
                </Typography>
                <RichTextField source={'translations.customerName'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.intro'
                  )}
                </Typography>
                <RichTextField source={'translations.intro'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.target_group_info'
                  )}
                </Typography>
                <RichTextField source={'translations.targetGroupInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.price_info'
                  )}
                </Typography>
                <RichTextField source={'translations.priceInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.rental_process_info'
                  )}
                </Typography>
                <RichTextField source={'translations.rentalProcessInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.opening_hours_info'
                  )}
                </Typography>
                <RichTextField source={'translations.openingHoursInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.accessibility_info'
                  )}
                </Typography>
                <RichTextField source={'translations.accessibilityInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.additional_info'
                  )}
                </Typography>
                <RichTextField source={'translations.additionalInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.contact_info'
                  )}
                </Typography>
                <RichTextField source={'translations.contactInfo'} />

                <Typography variant="h6" component="span">
                  {translate(
                    'app.show.locations.tabs.details.translations.closing_hours_info'
                  )}
                </Typography>
                <RichTextField source={'translations.closingHoursInfo'} />
              </TranslatableFields>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="app.show.locations.tabs.strollers.label">
              <LocationStrollers />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="app.show.locations.tabs.reservations.label">
              <LocationReservations />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="app.show.locations.tabs.opening_hours.label">
              <LocationOpeningHours />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="app.show.locations.tabs.coupons.label">
              <LocationCoupons />
            </TabbedShowLayout.Tab>
            {allowHeatMap === 'true' && (
              <TabbedShowLayout.Tab label="app.show.locations.tabs.heatmap.label">
                <LocationHeatMap />
              </TabbedShowLayout.Tab>
            )}
            {allowWalkLine === 'true' && (
              <TabbedShowLayout.Tab label="app.show.locations.tabs.walk_lines.label">
                <LocationWalkLines />
              </TabbedShowLayout.Tab>
            )}
          </TabbedShowLayout>
        </LocationResourceLayout>
      </Show>
    </>
  );
};

export default ShowLocation;
