import { Create } from 'react-admin';
import BeaconGroupForm from '../../Common/BeaconGroup/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateBeaconGroup = (props: CreateProps) => (
  <Create {...props}>
    <BeaconGroupForm />
  </Create>
);

export default CreateBeaconGroup;
