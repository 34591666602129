import { Grid, Typography } from '@mui/material';
import {
  ReferenceInput,
  required,
  TextInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  useTranslate,
  maxLength,
  minLength,
  useRecordContext,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormDataConsumer
} from 'react-admin';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { useState, useRef, useMemo, useEffect } from "react";
import { REACT_APP_MAPS_API_KEY } from '../../../constants';
import { requiredTextInput } from '../../../Validators/validate';
import _ from 'lodash';

// Function to calculate the centroid of the polygon
const getCentroid = (coords: any) => {
  var totalLat = 0, totalLng = 0;
  for (var i = 0; i < coords?.length; i++) {
      totalLat += coords[i].lat;
      totalLng += coords[i].lng;
  }
  var centroidLat = totalLat / coords?.length;
  var centroidLng = totalLng / coords?.length;
  return { lat: centroidLat, lng: centroidLng };
}

// Function to calculate the angle between two points and the centroid
const getAngle = (point: any, centroid: any) => {
  return Math.atan2(point.lat - centroid.lat, point.lng - centroid.lng);
}

const GeofenceMap = (props: any) => {
  const { geofence, setGeofence } = props;
  const geofenceCoordinates = useMemo(() => {
    return geofence?.geofenceCoordinates?.map((coordinate: any) => {
      return {
        lat: parseFloat(coordinate.latitude),
        lng: parseFloat(coordinate.longitude)
      };
    });
  }, [geofence?.geofenceCoordinates]);
  
  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    // Calculate centroid
    const centroid = getCentroid(geofenceCoordinates);

    // Sort coordinates based on angles around the centroid
    geofenceCoordinates?.sort(function(a: any, b: any) {
      return getAngle(a, centroid) - getAngle(b, centroid);
    });

    setCoordinates(geofenceCoordinates);
  }, [geofenceCoordinates]);

  const { isLoaded: googleMapIsLoaded } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_MAPS_API_KEY
  });

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEditMap = ((e: any) => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setCoordinates(nextPath);

      const convertPath = nextPath.map((coordinate: any) => {
        return {
          latitude: coordinate.lat,
          longitude: coordinate.lng
        };
      });

      let cloneGeofence = _.cloneDeep(geofence);
      let cloneGeofenceCoordinates = _.cloneDeep(cloneGeofence?.geofenceCoordinates);
      cloneGeofenceCoordinates = cloneGeofenceCoordinates?.map((coordinate: any) => {
        return {
          latitude: parseFloat(coordinate.latitude),
          longitude: parseFloat(coordinate.longitude)
        };
      });

      const newCoordinate = _.differenceWith(convertPath, cloneGeofenceCoordinates, _.isEqual);
      const removeCoordinate = _.differenceWith(cloneGeofenceCoordinates, convertPath, _.isEqual);
      
      if (newCoordinate.length > 0) {
        cloneGeofence?.geofenceCoordinates?.push(newCoordinate[0]);
      }

      if (removeCoordinate.length > 0) {
        const index = _.findIndex(cloneGeofenceCoordinates, removeCoordinate[0]);
        cloneGeofence?.geofenceCoordinates?.splice(index, 1);
      }

      cloneGeofence.geofenceCoordinates = cloneGeofence?.geofenceCoordinates?.map((coordinate: any) => {
        coordinate.latitude = coordinate.latitude.toString();
        coordinate.longitude = coordinate.longitude.toString();
        return coordinate;
      });

      setGeofence(cloneGeofence);
    }
  });

  const onLoadMap = ((polygon: any) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenersRef.current.push(
      path.addListener("set_at", onEditMap),
      path.addListener("insert_at", onEditMap),
      path.addListener("remove_at", onEditMap)
    );
  });

  return googleMapIsLoaded && coordinates ? (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={12} md={12}>
        <GoogleMap
          options={{ zoomControl: false, mapTypeControl: false, streetViewControl: false }}
          zoom={15}
          center={getCentroid(coordinates)}
          mapContainerStyle={{ width: "100%", height: "400px" }}>
          {coordinates && (
            <Polygon
              editable
              draggable
              path={coordinates}
              onMouseUp={onEditMap}
              onDragEnd={onEditMap}
              onLoad={onLoadMap}
            />
          )}
        </GoogleMap>
      </Grid>
    </Grid>
  ) : <></>;
}

const CustomToolbar = (props: any) => {
  const { origin } = props;

  return (
    <Toolbar {...props} sx={{display: 'flex', justifyContent: 'space-between'}} >
      <SaveButton alwaysEnable={origin === 'edit' ? true : false}/>
      <DeleteButton />
    </Toolbar>
  );
}

const GeofenceForm = (props: any) => {
  const translate = useTranslate();
  const { origin } = props;
  const geofenceDefaultValue = useRecordContext();
  const [geofence, setGeofence] = useState<any>({});

  useEffect(() => {
    setGeofence(geofenceDefaultValue);
  }, [geofenceDefaultValue]);

  const latLngDefaut = [...Array(3)].map(() => ({}));
  const handleChangeCoordinates = (record: any, geofence: any) => {    
    return geofence?.geofenceCoordinates?.length > 3 ? false : true;
  };

  return (
    <SimpleForm record={geofence} toolbar={<CustomToolbar origin={origin}/>}>
      { origin == 'edit' && <GeofenceMap geofence={geofence} setGeofence={setGeofence}/> }
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ marginTop: '20px'}}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {origin == 'create' ? translate('app.form.geofence.add_new_geofence') : translate('app.edit.geofence.title')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        {/* Name */}
        <Grid item xs={12} md={4}>
          <TextInput
            source={'name'}
            label="app.form.geofence.name"
            fullWidth
            validate={[required(), requiredTextInput(translate('ra.validation.required')), minLength(2), maxLength(255)]}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        {/* Workspace */}
        <Grid item xs={12} md={4}>
          <ReferenceInput
            source="workspace.@id"
            reference={'admin/workspaces'}
            name={'workspace'}
            perPage={300}
          >
            <AutocompleteInput
              sx={{ width: '100%' }}
              label={'app.form.geofence.workspace'}
              optionText={`name`}
              validate={[required()]}
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <FormDataConsumer>
        {({ formData }) => (
          <ArrayInput
            source="geofenceCoordinates"
            defaultValue={latLngDefaut}>
            <SimpleFormIterator
              disableRemove={(record) => handleChangeCoordinates(record, formData)}
              disableClear={true}
              inline>
              <TextInput
                source={'latitude'}
                label="app.form.geofence.latitude"
                helperText={false}
                validate={[required(), requiredTextInput(translate('ra.validation.required'))]}
              />
              <TextInput
                source={'longitude'}
                label="app.form.geofence.longitude"
                helperText={false}
                validate={[required(), requiredTextInput(translate('ra.validation.required'))]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default GeofenceForm;
