import {Create} from 'react-admin';
import OpeningHourForm from '../../Common/Opening_hours/Form';
import PeriodForm from '../../Common/Periods/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';
import {
  TabbedForm
} from 'react-admin';

const CreateOpeningHour = (props: CreateProps) => {
  return (
    <Create {...props} title={'app.show.locations.tabs.opening_hours.actions.create'} redirect={false}>
      <TabbedForm toolbar={false}>
        <TabbedForm.Tab label={'app.form.opening_hours.tab.hour_price'}>
          <OpeningHourForm isCreate />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'app.form.opening_hours.tab.active_period'} disabled>
          <PeriodForm isCreate />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default CreateOpeningHour;
