import { createElement, useRef, useEffect, useMemo } from 'react';

import Logo from '../../../Assets/logo-pink.svg';

import { Card, ThemeProvider, createTheme, styled, Box } from '@mui/material';

import { LoginProps } from 'ra-supabase';

export const AuthLayout: React.FunctionComponent<LoginProps> = (props) => {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    backgroundImage,
    ...rest
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const muiTheme = useMemo(() => createTheme(theme), [theme]);
  let backgroundImageLoaded = false;

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Root {...rest} ref={containerRef}>
        <Card className={AuthLayoutClasses.card}>
          <div className={AuthLayoutClasses.avatar}>
            <Box
              component="img"
              className={AuthLayoutClasses.icon}
              alt="Your logo."
              src={Logo}
            />
          </div>
          {children}
        </Card>
        {notification ? createElement(notification) : null}
      </Root>
    </ThemeProvider>
  );
};

const PREFIX = 'RaAuthLayout';

export const AuthLayoutClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage:
    'radial-gradient(circle at 50% 14em, #E29C73 0%, #F56B5F 60%, #F56B5F 100%)',
  [`& .${AuthLayoutClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
  [`& .${AuthLayoutClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${AuthLayoutClasses.icon}`]: {},
}));
