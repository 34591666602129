import { Create } from 'react-admin';
import StrollerForm from '../../Common/Strollers/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateStroller = (props: CreateProps) => {
  return (
    <Create {...props} title={'Stroller'} redirect={false}>
      <StrollerForm isCreate />
    </Create>
  );
};

export default CreateStroller;
