import {
  ImageField,
  number,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRecordContext
} from 'react-admin';
import {
  Alert,
  AlertTitle,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import {
  LinkButtons,
  ListButtons,
  RichTextInput,
  RichTextInputToolbar,
} from 'ra-input-rich-text';
import { RichTextInputProps } from 'ra-input-rich-text/src/RichTextInput';
import * as React from 'react';
import { MinimalFormatButtons } from '../MinimalFormatButtons';

const RichTextBox = (props: RichTextInputProps) => {
  return (
    <Card variant="outlined" sx={{ marginBottom: '15px', width: '100%' }}>
      <CardContent>
        <RichTextInput
          {...props}
          fullWidth
          toolbar={
            <RichTextInputToolbar>
              <MinimalFormatButtons />
              <ListButtons />
              <LinkButtons />
            </RichTextInputToolbar>
          }
        />
      </CardContent>
    </Card>
  );
};

const LocationForm = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <TabbedForm>
      <TabbedForm.Tab label="app.form.locations.general.label">
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <NumberInput
              sx={{ width: '100%' }}
              source={'strollerReservationPool'}
              label={'app.form.locations.general.stroller_reservation_pool'}
              validate={[required(), number()]}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} md={2}>
            <RadioButtonGroupInput
              source="publishStatus"
              label={'app.form.locations.general.publish_status.label'}
              validate={required()}
              choices={[
                {
                  id: 'draft',
                  name: 'app.form.locations.general.publish_status.draft',
                },
                {
                  id: 'published',
                  name: 'app.form.locations.general.publish_status.published',
                },
                {
                  id: 'offline',
                  name: 'app.form.locations.general.publish_status.offline',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {translate('app.form.locations.general.labels.image')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <ReferenceInput source="image.@id" reference="admin/media" >
              <SelectInput
                label={'app.form.locations.general.image'}
                optionText={'title'}
                sx={{ width: '100%' }}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={4}>
            <ImageField source={'image.contentUrl'} />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="h6">
              {translate('app.form.locations.general.address')}
            </Typography>
            <TextInput
              fullWidth={true}
              source={'address'}
              rows={3}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">
              {translate('app.form.locations.general.mollie_id')}
            </Typography>
            <TextInput
              fullWidth={true}
              source={'mollieId'}
            />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.form.locations.translations.label">
        <TranslatableInputs locales={['nl', 'en', 'fr']}>
          <TextInput
            source={'translations.name'}
            label="app.form.locations.translations.name"
            fullWidth
            validate={[required()]}
          />

          <TextInput
            source={'translations.customerName'}
            label={'app.form.locations.translations.customer_name'}
            fullWidth
            validate={[required()]}
          />

          <TextInput
            multiline={true}
            fullWidth={true}
            source={'translations.intro'}
            label={'app.form.locations.translations.intro'}
          />

          <RichTextBox
            source={'translations.targetGroupInfo'}
            label={'app.form.locations.translations.target_group_info'}
          />

          <RichTextBox
            source={'translations.priceInfo'}
            label={'app.form.locations.translations.price_info'}
          />

          <RichTextBox
            source={'translations.rentalProcessInfo'}
            label={'app.form.locations.translations.rental_process_info'}
          />

          <RichTextBox
            source={'translations.openingHoursInfo'}
            label={'app.form.locations.translations.opening_hours_info'}
          />

          <RichTextBox
            source={'translations.accessibilityInfo'}
            label={'app.form.locations.translations.accessibility_info'}
          />

          <RichTextBox
            source={'translations.additionalInfo'}
            label={'app.form.locations.translations.additional_info'}
          />

          <RichTextBox
            source={'translations.contactInfo'}
            label={'app.form.locations.translations.contact_info'}
          />

          <RichTextBox
            source={'translations.closingHoursInfo'}
            label={'app.form.locations.translations.closing_hours_info'}
          />
        </TranslatableInputs>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.form.locations.notes.label">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {translate('app.form.locations.notes.label')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <RichTextBox source={'internalNotes'} label={false} />
            <Alert severity="warning" sx={{ width: '100%' }}>
              <AlertTitle>
                {translate('app.form.locations.notes.alert.title')}
              </AlertTitle>
              {translate('app.form.locations.notes.alert.description')}
            </Alert>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.menu.beacon">
        <ReferenceArrayInput
          source="beaconGroups"
          reference={'admin/locations/'+ record?.originId +'/beacon-groups'}
          perPage={300}
        >
          <AutocompleteArrayInput
            sx={{ minWidth: '35%' }}
            label={'app.form.beaconGroup.beaconGroup.title'}
            optionText={`name`}
          />
        </ReferenceArrayInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.menu.geofence">
        <ReferenceArrayInput
          source="geofence"
          reference={'admin/locations/'+ record?.originId +'/geofences'}
          perPage={300}
        >
          <AutocompleteArrayInput
            sx={{ minWidth: '35%' }}
            label={'app.form.geofence.title'}
            optionText={`name`}
          />
        </ReferenceArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default LocationForm;
