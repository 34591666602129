import {
  transformTranslationsToApiModel,
  transformTranslationsToViewModel,
} from '../Transformer/TranslationsTransformer';

import _, { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/Location';

export const locationsLifecycleCallbacks = {
  resource: 'admin/locations',
  afterRead: async (data, dataProvider) => {
    data.translations = transformTranslationsToViewModel(
      get(data, 'translations')
    );

    let image = get(data, 'image', {});
    if (get(image, 'contentUrl', false)) {
      set(image, 'src', get(image, 'contentUrl'));
      set(data, 'image', image);
    }

    data.beaconGroups = _.map(data.beaconGroups, '@id');
    data.geofence = _.map(data.geofence, '@id');

    return data;
  },
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
    set(
      data,
      'translations',
      transformTranslationsToApiModel(get(data, 'translations'))
    );

    if (get(data.image, '@id', false)) {
      set(data, 'image', get(data.image, '@id'));
    } else {
      delete data.image;
    }

    return data;
  },
  afterCreate: async (data, dataProvider) => {
    set(
      data,
      'translations',
      transformTranslationsToViewModel(get(data, 'translations', {}))
    );

    return data;
  },
};
