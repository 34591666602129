import {
  DateInput,
  useRecordContext,
  useRefresh,
  useGetList,
} from 'react-admin';
import * as React from 'react';
import { useRef } from "react";
import {useForm, FormProvider} from 'react-hook-form';
import {
  FeatureGroup,
  MapContainer,
  Polyline,
  TileLayer
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const LocationWalkLines = () => {
  const record = useRecordContext();
  const mapRef = useRef();
  const date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const firstDayData = new Date(y, m, 2);
  const lastDayData = new Date(y, m + 1, 0);
  const refresh = useRefresh();
  //default form values
  const form = useForm({
    defaultValues: {
      createdAt: {
        after: firstDayData.toISOString().split('T')[0],
        before: lastDayData.toISOString().split('T')[0],
      }
    },
  });

  //get data map
  let walkLineDatas : any = [];
  const { data } = useGetList(
    'admin/walklines',
    {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        location: record.id,
        createdAt: {
          before : form.getValues().createdAt.before + 'T23:59:59+00:00',
          after : form.getValues().createdAt.after + 'T00:00:00+00:00',
        }
      }
    }
  );

  //map data for line walk
  data && data.map((walkLine:any) => {
    const trackings = JSON.parse(walkLine?.tracking);
    const latLng = trackings.map((tracking:any) => {
      return [tracking.lat, tracking.lng];
    });

    walkLineDatas.push(latLng);
    return walkLineDatas;
  });

  const onFilter = () => {
    refresh();
  };

  if (!record) return null;

  return (
    <>
      <FormProvider {...form}>
        <form>
          <DateInput
            source={'createdAt[after]'}
            label={'app.form.coupons.general.start_date'}
            onChange={() => onFilter()}
            style={{ marginRight: 10 }}
          />
          <DateInput
            source={'createdAt[before]'}
            label={'app.form.coupons.general.end_date'}
            onChange={() => onFilter()}
          />
        </form>
      </FormProvider>
      {walkLineDatas.length > 0 ? (
        <div>
          <MapContainer
            style={{ height: "480px", width: "100%" }}
            zoom={13}
            loadingControl={false}
            center={walkLineDatas[0][0]}
            ref={mapRef}
            scrollWheelZoom={true}
          >
            <FeatureGroup>
              {walkLineDatas.map((walkLineData:any, index:number) => {
                return (
                  <Polyline
                    key={index}
                    pathOptions={{ color: "red" }}
                    positions={walkLineData}
                  />
                );
              })}
            </FeatureGroup>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </MapContainer>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default LocationWalkLines;
