import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  TopToolbar,
  useLocaleState,
  WrapperField,
} from 'react-admin';
import { ListProps } from 'ra-ui-materialui/src/list/List';

const LocationList = (props: ListProps) => {
  const [locale] = useLocaleState();

  const ListActions = () => (
    <TopToolbar>
      <CreateButton label={'app.list.locations.actions.create'} />
    </TopToolbar>
  );

  return (
    <List {...props} actions={<ListActions />} sort={{ field: 'translations.name', order: 'ASC' }}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={'show'}
        size={'medium'}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <TextField
          source={`translations.name.${locale}`}
          label={'app.list.locations.name'}
        />
        <TextField
          // source={'numberOfAvailableStrollersForRegistration'}
          source={'totalAvailableStrollers'}
          label={'app.list.locations.available_strollers'}
        />
        <TextField
          // source={'strollerReservationPool'}
          source={'totalStrollers'}
          label={'app.list.locations.total_strollers'}
        />
        <WrapperField>
          <ShowButton />
          <EditButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default LocationList;
