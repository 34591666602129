import {
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CancelReservationButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.cancelReservation(record, dataProvider).then(() => {
      refresh();
      notify('app.show.locations.tabs.reservations.actions.cancelled');
    })
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  let date = new Date(record.date);

  let modalContent = translate(
    'app.show.locations.tabs.reservations.actions.modal.content',
    {
      person: `${record.reservedBy.firstName} ${record.reservedBy.lastName}`,
      date: date.toLocaleDateString(),
    }
  );

  return (
    <>
      <IconButton
        aria-label="Example"
        onClick={handleClick}
        disabled={isLoading}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={'app.show.locations.tabs.reservations.actions.modal.title'}
        content={modalContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const LocationReservations = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <List
      resource={'admin/reservations'}
      filter={{ location: record.id }}
      exporter={false}
      actions={false}
      title={<>{translate('app.show.locations.tabs.reservations.title')}</>}
      hasCreate={false}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <DateField
          showDate
          source={'date'}
          locales={'en-GB'}
          label={'app.show.locations.tabs.reservations.table.date'}
        />
        <FunctionField
          label={'app.show.locations.tabs.reservations.table.name'}
          render={(record: any) =>
            `${record.reservedBy.firstName ? record.reservedBy.firstName : ''} ${record.reservedBy.lastName ? record.reservedBy.lastName : ''}`
          }
        />
        <TextField
          source={'status'}
          sortable={false}
          label={'app.show.locations.tabs.reservations.table.status'}
        />
        <TextField
          source={'reservedBy.email'}
          sortable={false}
          label={'app.show.locations.tabs.reservations.table.email'}
        />
        <TextField
          source={'reservedBy.phone'}
          label={'app.show.locations.tabs.reservations.table.phone'}
        />
        <FunctionField
          render={(record: any) =>
            record.checkIns
              .map(
                (checkIn: { stroller: { code: string } }) =>
                  checkIn.stroller.code
              )
              .join(', ')
          }
          label={'app.show.locations.tabs.reservations.table.strollers'}
        />
        <CancelReservationButton />
      </Datagrid>
    </List>
  );
};

export default LocationReservations;
