import {
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
  minLength,
  useTranslate
} from 'react-admin';
import {
  Grid,
} from '@mui/material';
import * as React from 'react';
import { requiredTextInput } from '../../../Validators/validate';

const BeaconGroupForm = () => {
  const translate = useTranslate();

  return (
    <TabbedForm>
      <TabbedForm.Tab label="app.form.beaconGroup.beaconGroup.title">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          {/* Name */}
          <Grid item xs={12} md={4}>
            <TextInput
              source={'name'}
              label="app.form.beaconGroup.beaconGroup.name"
              fullWidth
              validate={[required(), requiredTextInput(translate('ra.validation.required')), minLength(2), maxLength(255)]}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          {/* Workspace */}
          <Grid item xs={12} md={4}>
            <ReferenceInput
              source="workspace.@id"
              reference={'admin/workspaces'}
              name={'workspace'}
              perPage={300}
            >
              <AutocompleteInput
                sx={{ width: '100%' }}
                label={'app.form.beaconGroup.beaconGroup.workspace'}
                optionText={`name`}
                validate={[required()]}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.form.beaconGroup.beacon.title">
        <ArrayInput source="beacons">
          <SimpleFormIterator inline>
            <TextInput
              source={'name'}
              label="app.form.beaconGroup.beaconGroup.name"
              helperText={false}
              validate={[required(), requiredTextInput(translate('ra.validation.required')), minLength(2), maxLength(255)]}
              />
            <TextInput
              source={'uuid'}
              label="app.form.beaconGroup.beacon.uuid"
              helperText={false}
              validate={[required(), requiredTextInput(translate('ra.validation.required')), minLength(36), maxLength(36)]}
            />
          </SimpleFormIterator>
        </ArrayInput>    
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default BeaconGroupForm;
