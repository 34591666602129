import { authProvider } from './Util/authProvider';
import {useEffect, useState} from 'react';
import { BrowserRouter, Navigate, Route } from 'react-router-dom';
import {CustomRoutes, useRefresh} from 'react-admin';
import LocationList from './Components/Lists/Locations/List.tsx';
import DashboardList from './Components/Lists/Dashboard/List.tsx';
import DashboardOwnerList from './Components/Lists/Dashboard/OwnerList.tsx';
import WorkspaceList from './Components/Lists/Workspaces/List.tsx';
import AdministratorList from './Components/Lists/Users/List.tsx';
import MediaList from './Components/Lists/Media/List.tsx';
import ShowLocation from './Components/Show/Locations/Show.tsx';
import ShowMedia from './Components/Show/Media/Show.tsx';
import EditLocation from './Components/Edit/Locations/Edit.tsx';
import EditWorkspace from './Components/Edit/Workspaces/Edit.tsx';
import EditAdministrator from './Components/Edit/Users/Edit.tsx';
import CreateMedia from './Components/Create/Media/Create.tsx';
import i18nProvider from './Util/i18nProvider';
import Layout from './Components/Layout';
import {
  HydraAdmin,
  ResourceGuesser,
  useIntrospection,
} from '@api-platform/admin';
import dataProvider from './Util/dataProvider';
import theme from './Components/Layout/Theme';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ImageIcon from '@mui/icons-material/Image';
import { LoginPage } from './Components/Layout/LoginPage.tsx';
import CreateLocation from './Components/Create/Locations/Create.tsx';
import CreateWorkspace from './Components/Create/Workspaces/Create.tsx';
import CreateAdministrator from './Components/Create/Users/Create.tsx';
import StrollerList from './Components/Lists/Strollers/List';
import StrollerIcon from '@mui/icons-material/Stroller';
import EditStroller from './Components/Edit/Strollers/Edit';
import ShowStroller from './Components/Show/Strollers/Show';
import CreateStroller from './Components/Create/Strollers/Create';
import CouponIcon from '@mui/icons-material/Stroller';
import CreateCoupon from './Components/Create/Coupons/Create';
import EditCoupon from './Components/Edit/Coupons/Edit';
import OpeningHoursIcon from '@mui/icons-material/Stroller';
import CreateOpeningHours from './Components/Create/Opening_hours/Create';
import EditOpeningHours from './Components/Edit/Opening_hours/Edit';
import PaymentList from './Components/Lists/Payments/List';
import PaymentIcon from '@mui/icons-material/Payment';
import EditPayment from './Components/Edit/Payments/Edit';
import ListIcon from '@mui/icons-material/List';
import BeaconGroupList from './Components/Lists/BeaconGroup/List';
import CreateBeaconGroup from './Components/Create/BeaconGroup/Create';
import EditBeaconGroup from './Components/Edit/BeaconGroup/Edit';
import GeofenceList from './Components/Lists/Geofence/List';
import CreateGeofence from './Components/Create/Geofence/Create';
import EditGeofence from './Components/Edit/Geofence/Edit';

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem('token')) {
    introspect();
    return <></>;
  }

  return <Navigate to="/login" />;
};

const App = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  return (
    <>
      <BrowserRouter>
        <HydraAdmin
          theme={theme}
          dashboard={DashboardList}
          dataProvider={dataProvider(setRedirectToLogin)}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={Layout}
          loginPage={<LoginPage providers={['google']} disableForgotPassword />}
          entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
          requireAuth
          disableTelemetry
        >
          <CustomRoutes noLayout>
            {redirectToLogin ? (
              <Route path="/" element={<RedirectToLogin />} />
            ) : null}
          </CustomRoutes>
          <ResourceGuesser
            name="admin/users"
            options={{ label: 'app.menu.dashboard' }}
            list={DashboardOwnerList}
            icon={PinDropIcon}
          />
          <ResourceGuesser
            name="admin/locations"
            options={{ label: 'app.menu.locations' }}
            list={LocationList}
            show={ShowLocation}
            edit={EditLocation}
            create={CreateLocation}
            icon={PinDropIcon}
          />
          <ResourceGuesser
            name={'admin/strollers'}
            options={{ label: 'app.menu.strollers' }}
            list={StrollerList}
            edit={EditStroller}
            show={ShowStroller}
            create={CreateStroller}
            icon={StrollerIcon}
          />
          <ResourceGuesser
            name="admin/media"
            options={{ label: 'app.menu.media' }}
            list={MediaList}
            show={ShowMedia}
            create={CreateMedia}
            icon={ImageIcon}
          />
          <ResourceGuesser
            name="admin/payments"
            options={{ label: 'app.menu.payments' }}
            list={PaymentList}
            edit={EditPayment}
            icon={PaymentIcon}
          />
          <ResourceGuesser
            name={'admin/coupons'}
            create={CreateCoupon}
            edit={EditCoupon}
            icon={CouponIcon}
          />
          <ResourceGuesser
            name={'admin/opening_hours'}
            create={CreateOpeningHours}
            edit={EditOpeningHours}
            icon={OpeningHoursIcon}
          />
          <ResourceGuesser
            name="admin/beacon-group"
            options={{ label: 'app.menu.beacon' }}
            list={BeaconGroupList}
            edit={EditBeaconGroup}
            create={CreateBeaconGroup}
            icon={ListIcon}
          />
          <ResourceGuesser
            name="admin/geofence"
            options={{ label: 'app.menu.geofence' }}
            list={GeofenceList}
            edit={EditGeofence}
            create={CreateGeofence}
            icon={ListIcon}
          />
          <ResourceGuesser
            name="admin/workspaces"
            options={{ label: 'app.menu.workspaces' }}
            list={WorkspaceList}
            edit={EditWorkspace}
            create={CreateWorkspace}
            icon={PinDropIcon}
          />
          <ResourceGuesser
            name="admin/administrator"
            options={{ label: 'app.menu.administrator' }}
            list={AdministratorList}
            edit={EditAdministrator}
            create={CreateAdministrator}
            icon={PinDropIcon}
          />
        </HydraAdmin>
      </BrowserRouter>
    </>
  );
};

export default App;
