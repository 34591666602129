import { get, set } from 'lodash';

const transformTranslationsToViewModel = (oldTranslations) => {
  let newTranslation = {};

  Object.entries(oldTranslations).forEach(([locale, translation]) => {
    Object.entries(translation).forEach(([key, value]) => {
      if (key !== '@id' && key !== '@type' && key !== 'id') {
        const translationKeyValue = get(newTranslation, key, {});
        set(translationKeyValue, locale, value);
        set(newTranslation, key, translationKeyValue);
      }
    });
  });

  return newTranslation;
};

// The inverse of the above function
const transformTranslationsToApiModel = (oldTranslations) => {
  let newTranslation = {};

  Object.entries(oldTranslations).forEach(([key, translation]) => {
    Object.entries(translation).forEach(([locale, value]) => {
      const translationKeyValue = get(newTranslation, locale, {});
      set(translationKeyValue, key, value);
      set(newTranslation, locale, translationKeyValue);
    });
  });

  let finalTranslations = {};
  Object.entries(newTranslation).forEach(([locale, translation]) => {
    if (Object.keys(translation).length !== 0) {
      finalTranslations[locale] = translation;
    }
    set(finalTranslations[locale], 'locale', locale);
  });

  return finalTranslations;
};

export { transformTranslationsToViewModel, transformTranslationsToApiModel };
