import { Create } from 'react-admin';
import GeofenceForm from '../../Common/Geofence/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateGeofenceGroup = (props: CreateProps) => (
  <Create {...props} redirect={'list'}>
    <GeofenceForm origin="create" />
  </Create>
);

export default CreateGeofenceGroup;
