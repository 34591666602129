// in src/MyMenu.js
import {Menu, useDataProvider, useRefresh} from 'react-admin';

export const AppMenu = () => {
  const dataProvider = useDataProvider();
  const localWorkspace = localStorage.getItem('workspace');
  const allowHeatMap = localStorage.getItem('allowHeatMap') === 'true' ? true : false;
  const allowWalkLine = localStorage.getItem('allowWalkLine') === 'true' ? true : false
  const hasAdminRights = localStorage.getItem('hasAdminRights') === 'true' ? true : false;
  const is_owner = localStorage.getItem('is_owner') === 'true' ? true : false;
  let finalWorkspace = localWorkspace;
  let finalHasAdminRights = hasAdminRights;
  let finalIsOwner = is_owner;
  const refresh = useRefresh();
  dataProvider.getWorkspaces(dataProvider).then((dataWorkspaces) => {
    if (dataWorkspaces?.length > 0) {
      dataProvider.getUser(dataProvider, null, null).then((data) => {
        let workspaceTmp = null;
        if (!localWorkspace) {
          const workspace = dataWorkspaces?.[0];
          localStorage.setItem('workspace', workspace?.originId);
          finalWorkspace = workspace?.originId;
          workspaceTmp = workspace;
        } else {
          const newWorkspace = dataWorkspaces?.find((workspace) => workspace?.originId == localWorkspace) ?? dataWorkspaces?.[0];
          workspaceTmp = newWorkspace;
        }

        if (data?.data?.roles?.includes("ROLE_OWNER")) {
          localStorage.setItem('is_owner', true);
          localStorage.setItem('allowHeatMap', true);
          localStorage.setItem('allowWalkLine', true);
          localStorage.setItem('hasAdminRights', true);
          finalIsOwner = true;
          finalHasAdminRights = true;
          // Check if the user is owner
          if (!is_owner) {
            refresh();
          }
        } else {
          localStorage.setItem('is_owner', false);
          finalIsOwner = false;
          if (allowHeatMap == null || allowHeatMap != workspaceTmp?.allowHeatMap
            || allowWalkLine == null || allowWalkLine != workspaceTmp?.allowWalkLine
            || hasAdminRights == null || hasAdminRights != workspaceTmp?.hasAdminRights) {
            localStorage.setItem('allowHeatMap', workspaceTmp?.allowHeatMap);
            localStorage.setItem('allowWalkLine', workspaceTmp?.allowWalkLine);
            localStorage.setItem('hasAdminRights', workspaceTmp?.hasAdminRights);
            finalHasAdminRights = workspaceTmp?.hasAdminRights;
            refresh();
          }
        }
      });
    } else {
      if (localWorkspace) {
        localStorage.removeItem('workspace');
        localStorage.removeItem('allowHeatMap');
        localStorage.removeItem('allowWalkLine');
        localStorage.removeItem('hasAdminRights');
        localStorage.removeItem('is_owner');
        refresh();
      }
    }
  });

  return (
    <>
      {finalWorkspace ? (
        <Menu>
          <Menu.DashboardItem />
          {finalIsOwner && (
            <Menu.ResourceItem name="admin/users" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/locations" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/strollers" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/media" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/payments" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/beacon-group" />
          )}
          {(finalHasAdminRights  || finalIsOwner) && (
            <Menu.ResourceItem name="admin/geofence" />
          )}
          {finalIsOwner && (
            <Menu.ResourceItem name="admin/workspaces" />
          )}
          {finalIsOwner && (
            <Menu.ResourceItem name="admin/administrator" />
          )}
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
};
