import { FunctionField, useTranslate } from 'react-admin';
import { get } from 'lodash';

import { PublicFieldProps } from 'ra-ui-materialui/src/field/types';

const TranslatedField = (props: TranslatedFieldProps) => {
  const { source, prefix } = props;
  const translate = useTranslate();
  return (
    <FunctionField
      {...props}
      render={(record: any) =>
        record && translate(`${prefix}.${get(record, source)}`)
      }
    />
  );
};

export default TranslatedField;

export interface TranslatedFieldProps extends PublicFieldProps {
  source: string;
  prefix: string;
}
