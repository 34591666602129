import { set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/Workspace';

export const workspacesLifecycleCallbacks = {
  resource: 'admin/workspaces',
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
    if (data.locationIds) {
      set(data, 'locationIds', data.locationIds.map((locationId) => {
        return locationId.replace('/api/admin/locations/', '');
      }));
    } else {
      data.locationIds = null;
    }

    if (data.userIds) {
      set(data, 'userIds', data.userIds.map((userId) => {
        return userId.replace('/api/admin/users/', '');
      }));
    } else {
      data.userIds = null;
    }

    set(data, 'active', true);
    return data;
  },
  afterSave: async (data, dataProvider) => {
    if (data.name) {
      data.locationIds = data.locationIds.map((locationId) => {
        return '/api/admin/locations/' + locationId;
      });

      data.userIds = data.userIds.map((userId) => {
        return '/api/admin/users/' + userId;
      });
    }

    return data;
  },
  afterRead: async (data, dataProvider) => {
    if (data.name) {
      data.locationIds = data.locationIds.map((locationId) => {
        return '/api/admin/locations/' + locationId;
      });

      data.userIds = data.userIds.map((userId) => {
        return '/api/admin/users/' + userId;
      });
    }

    return data;
  }
};
