import { Grid, Typography } from '@mui/material';

import {
  AutocompleteInput,
  DateTimeInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useLocaleState,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = (props: ToolbarProps) => {
  const { reset } = useFormContext();
  const notify = useNotify();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            window.scrollTo(0, 0);
            notify('app.form.payments.edit.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

const PaymentForm = (props: CreatePaymentFormProps) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {translate('app.form.payments.general.labels.payment_info')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ReferenceInput
            source="user.@id"
            reference={'admin/users'}
            name={'user'}
            perPage={300}
          >
            <AutocompleteInput
              sx={{ width: '100%' }}
              label={'app.form.payments.general.user'}
              optionText={`email`}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ReferenceInput
            source="location.@id"
            reference={'admin/locations'}
            perPage={300}
            name={'location'}
          >
            <AutocompleteInput
              sx={{ width: '100%' }}
              label={'app.form.payments.general.location'}
              optionText={`translations.name.${locale}`}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <NumberInput
            source={'amount'}
            sx={{ width: '100%' }}
            label={'app.form.payments.general.amount'}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DateTimeInput
            source={'createdAt'}
            sx={{ width: '100%' }}
            label={'app.form.payments.general.created_at'}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ReferenceInput
            source="stroller.@id"
            reference={'admin/strollers'}
            name={'stroller'}
            perPage={300}
          >
            <AutocompleteInput
              sx={{ width: '100%' }}
              label={'app.form.payments.general.stroller'}
              optionText={`code`}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <NumberInput
            source={'paid'}
            sx={{ width: '100%' }}
            label={'app.form.payments.general.paid'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {translate('app.form.payments.general.labels.payment_status')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <RadioButtonGroupInput
            source="status"
            label={'app.form.payments.general.status.label'}
            validate={required()}
            sx={{ width: '100%' }}
            defaultValue={0}
            choices={[
              {
                id: 0,
                name: 'app.common.payments.status.0',
              },
              {
                id: 1,
                name: 'app.common.payments.status.1',
              },
              {
                id: 2,
                name: 'app.common.payments.status.2',
              },
              {
                id: 3,
                name: 'app.common.payments.status.3',
              },
              {
                id: 4,
                name: 'app.common.payments.status.4',
              },
              {
                id: 5,
                name: 'app.common.payments.status.5',
              },
            ]}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default PaymentForm;

export interface CreatePaymentFormProps {
  isCreate?: boolean;
}
