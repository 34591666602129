import {
  Create,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateMedia = (props: CreateProps) => {
  return (
    <Create {...props} title={'app.create.media'}>
      <SimpleForm>
        <TextInput name={'title'} source={'title'} validate={[required()]} />
        <ImageInput
          source="file"
          label="Photoooooooo"
          accept="image/png, image/jpeg, image/jpg"
          maxSize={2000000} // 2MB
          sx={{
            '& .RaFileInput-dropZone': {
              backgroundColor: '#eee',
            },
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateMedia;
