import {Layout} from 'react-admin';
import AppBar from './Layout/Toolbar';
import { AppMenu } from './Layout/Menu';

const AppLayout = (props) => {
    return (
        <Layout {...props} appBar={AppBar} menu={AppMenu} />
    )
};

export default AppLayout;
