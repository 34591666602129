import polyglotI18nProvider from 'ra-i18n-polyglot';
import messages from '../config/i18n/index.ts';

export const locales = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'nl',
    name: 'Nederlands',
  },
];

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  'en',
  locales
);

export default i18nProvider;
