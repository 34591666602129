import { supabaseAuthProvider } from 'ra-supabase-core';
import { supabase } from './supabase';

export const authProvider = supabaseAuthProvider(supabase, {
  getIdentity: async (user) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data || error) {
      throw new Error();
    }

    return {
      id: data.user.id,
      fullName: `${data.user.email}`,
    };
  },
});
