import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  WrapperField,
  TopToolbar, DeleteWithConfirmButton, EditButton,
} from 'react-admin';
import {Button} from '@mui/material';
import * as React from "react";
import ContentAdd from "@mui/icons-material/Add";
import {Link} from "react-router-dom";
import { ListProps } from 'ra-ui-materialui/src/list/List';

const StrollerList = (props: ListProps) => {
  const translate = useTranslate();

  return (
    <>
      <TopToolbar>
        <Button
          startIcon={<ContentAdd />}
          component={Link}
          to={{
            pathname: '/admin/workspaces/create',
          }}
        >
          {translate('app.show.workspaces.actions.create')}
        </Button>
      </TopToolbar>
      <List
        {...props}
        actions={false}
      >
        <Datagrid
          bulkActionButtons={false}
          size={'medium'}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={'name'}
            label={'app.show.workspaces.table.name'}
          />
          <TextField
            source={'description'}
            label={'app.show.workspaces.table.description'}
          />
          <WrapperField label={'app.show.workspaces.table.action'}>
            <DeleteWithConfirmButton />
            <EditButton />
          </WrapperField>
        </Datagrid>
      </List>
    </>
  );
};

export default StrollerList;
