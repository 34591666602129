import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import { hydraDataProvider as baseHydraDataProvider } from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { withLifecycleCallbacks } from 'ra-core';
import { locationsLifecycleCallbacks } from './CallBacks/locations';
import { beaconGroupLifecycleCallbacks } from './CallBacks/beaconGroup';
import { geofenceLifecycleCallbacks } from './CallBacks/geofence';
import { supabase } from './supabase';
import { DataProvider } from 'ra-core';
import { strollersLifecycleCallbacks } from './CallBacks/strollers';
import {couponsLifecycleCallbacks} from "./CallBacks/coupons";
import {paymentsLifecycleCallbacks} from "./CallBacks/payments";
import {openingHoursLifecycleCallbacks} from "./CallBacks/openingHours";
import { workspacesLifecycleCallbacks } from "./CallBacks/workspaces";
import {administratorLifecycleCallbacks} from "./CallBacks/administrator";

const fetchHydra = async (url, options = {}) => {
  const { data } = await supabase.auth.getSession();
  const workspaceId = localStorage.getItem('workspace');

  // Add the locale to the query if it's not the default one
  url.searchParams.set('locale', (localStorage.getItem('RaStore.locale') || 'en').replace(/"/g, ''));

  if (!options.headers) options.headers = new Headers({});

  options.headers.set('X-Workspace-ID', Number(workspaceId));
  if (data.session) {
    options.user = {
      authenticated: true,
      token: `Bearer ${data.session.access_token}`,
    };
  }

  return baseFetchHydra(url, {
    ...options,
  });
};

const apiDocumentationParser = (setRedirectToLogin) => async () => {
  try {
    setRedirectToLogin(false);
    const { data } = await supabase.auth.getSession();
    let options = {};
    options.headers = new Headers({});

    if (data.session) {
      options.user = {
        authenticated: true,
        token: `Bearer ${data.session.access_token}`,
      };
    }

    return await parseHydraDocumentation(
      process.env.REACT_APP_API_ENTRYPOINT,
      options
    );
  } catch (result) {
    const { api, response, status } = result;

    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem('token');
    setRedirectToLogin(true);

    return {
      api,
      response,
      status,
    };
  }
};

const baseDataProvider = (setRedirectToLogin) =>
  baseHydraDataProvider({
    entrypoint: process.env.REACT_APP_API_ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
  });

const dataProvider = (setRedirectLogin) => {
  return {
    ...withLifecycleCallbacks(baseDataProvider(setRedirectLogin), [
      locationsLifecycleCallbacks,
      strollersLifecycleCallbacks,
      couponsLifecycleCallbacks,
      paymentsLifecycleCallbacks,
      openingHoursLifecycleCallbacks,
      beaconGroupLifecycleCallbacks,
      geofenceLifecycleCallbacks,
      workspacesLifecycleCallbacks,
      administratorLifecycleCallbacks,
    ]),
    cancelReservation: (reservation, dataProvider: DataProvider) => {
      const newReservation = {
        id: reservation.id,
        status: 'cancelled',
      };

      return dataProvider.update('api/admin/reservations', {
        data: JSON.stringify(newReservation),
        id: 'api/admin/reservations/' + reservation.originId,
      });
    },
    cancelCoupon: async (coupon, dataProvider: DataProvider) => {
      const { data } = await supabase.auth.getSession();
      return new Promise((resolve, reject) => {
        return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/admin/coupons/${coupon.originId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${data.session.access_token}`,
          },
        })
          .then(res => {
            if (!res.ok) {
              return res.json();
            } else {
              resolve({
                id: coupon.originId,
              });
            }
          })
          .then(json => {
            if (json.error) {
              reject(new Error(json.error.message));
            }
            resolve(json);
          });
      });
    },
    cancelOpeningHour: async (openingHour, dataProvider: DataProvider) => {
      const { data } = await supabase.auth.getSession();
      return new Promise((resolve, reject) => {
        return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/admin/opening_hours/${openingHour.originId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${data.session.access_token}`,
          },
        })
          .then(res => {
            if (!res.ok) {
              return res.json();
            } else {
              resolve({
                id: openingHour.originId,
              });
            }
          })
          .then(json => {
            if (json.error) {
              reject(new Error(json.error.message));
            }
            resolve(json);
          });
      });
    },
    cancelPeriod: (period, dataProvider: DataProvider) => {
      return dataProvider.delete(`api/admin/opening_hours/${period.openingHour.id}/periods/${period.originId}`, {
        id: `api/admin/opening_hours/${period.openingHour.id}/periods/${period.originId}`,
      });
    },
    createPeriod: async (openingHour, values, dataProvider: DataProvider) => {
      const { data } = await supabase.auth.getSession();
      const newPeriod = {
        endDate: values.endDate + 'T00:00:00.924Z',
        startDate: values.startDate + 'T00:00:00.924Z',
        openingHour: `/api/admin/opening_hours/${openingHour.originId}`,
      };
      return new Promise((resolve, reject) => {
        return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/admin/opening_hours/$s{openingHour.originId}/periods`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${data.session.access_token}`,
          },
          body: JSON.stringify(newPeriod)
        })
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              reject(new Error(json.error.message));
            }
            resolve(json);
          });
      });
    },
    getWalkLines: async (location, dataProvider: DataProvider) => {
      const response = await dataProvider.getList('admin/walklines', {
        pagination: { page: 1, perPage: 200 },
        sort: {},
        filter: { location: location.id },
      });

      return response.data;
    },
    getUser: async (dataProvider: DataProvider, workspace, filter) => {
      const response = await dataProvider.getOne(`user?${workspace ? `&workspace=${workspace}` : ''}${filter ? `&period=${filter}` : ''}`, {});

      return response;
    },
    getWorkspaces: async (dataProvider: DataProvider) => {
      const response = await dataProvider.getList('admin/workspaces', {
        pagination: { page: 1, perPage: 200 },
        sort: {},
        filter: {},
      });

      return response.data;
    },
    importStroller: async (file, dataProvider: DataProvider) => {
      const { data } = await supabase.auth.getSession();
      let formData = new FormData();
      formData.append('file', file);
      return new Promise((resolve, reject) => {
        return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/admin/strollers/actions/import`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${data.session.access_token}`,
          },
          body: formData
        })
          .then(res => {
            if (!res.ok) {
              return res.json();
            } else {
              resolve({
                code: 200,
              });
            }
          })
          .then(json => {
            if (json.error) {
              reject(new Error(json.error.message));
            }
            resolve(json);
          });
      });
    },
  };
};

export default dataProvider;
