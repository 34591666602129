import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  WrapperField,
  DeleteWithConfirmButton
} from 'react-admin';

import { ListProps } from 'ra-ui-materialui/src/list/List';

const GeofenceList = (props: ListProps) => {
  const ListActions = () => (
    <TopToolbar>
      <CreateButton label={'app.list.geofence.actions.create'} />
    </TopToolbar>
  );

  return (
    <List
      {...props}
      actions={<ListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        size={'medium'}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <TextField
          source={`name`}
          label={'app.list.geofence.name'}
          sortable={false}
        />
        <WrapperField>
          <EditButton />
          <DeleteWithConfirmButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default GeofenceList;
