export const validUpdateFields = [
  'id',
  'strollerReservationPool',
  'minimumStrollerAvailabilityThresholdPercentage',
  'publishStatus',
  'translations',
  'image',
  'address',
  'beaconGroups',
  'geofence',
  'internalNotes',
  'mollieId'
];
