const defaultValidFields = ['@context', '@id', '@type'];

export const cleanFields = (model, validFields) => {
  const allValidFields = defaultValidFields.concat(validFields);

  Object.keys(model).forEach(
    (key) => allValidFields.includes(key) || delete model[key]
  );

  return model;
};
