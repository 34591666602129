import { get, set } from 'lodash';
import {cleanFields} from "../Model/ApiModelCleaner";
import {validUpdateFields} from "../Model/Payment";

export const paymentsLifecycleCallbacks = {
  resource: 'admin/payments',
  afterGetList: async (data, dataProvider) => {
    if (data?.data) {
      data?.data?.map((payment) => {
        let amount = get(payment, 'amount', {});
        if (amount > 0) {
          set(payment, 'amount', `€${payment.amount}`);
        } else {
          set(payment, 'amount', `-`);
        }

        let paid = get(payment, 'paid', {});
        if (paid > 0) {
          set(payment, 'paid', `€${payment.paid}`);
        } else {
          set(payment, 'paid', `-`);
        }

        return payment;
      });
    }

    return data;
  },
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);

    if (get(data.location, '@id', false)) {
      set(data, 'location', get(data.location, '@id'));
    } else {
      data.location = null;
    }

    if (get(data.user, '@id', false)) {
      set(data, 'user', get(data.user, '@id'));
    } else {
      data.user = null;
    }

    if (get(data.stroller, '@id', false)) {
      set(data, 'stroller', get(data.stroller, '@id'));
    } else {
      data.stroller = null;
    }

    if (data.status) {
      set(data, 'status', Number(data.status));
    } else {
      data.stroller = null;
    }

    return data;
  },
};
