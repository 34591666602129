import Logo from '../../Assets/logo.svg';
import {
  AppBar as BaseAppBar,
  useAuthProvider,
  useDataProvider,
  useRefresh
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';

const SettingsButton = () => {
  const dataProvider = useDataProvider();
  const [workspace, setWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState(null);
  const refresh = useRefresh();
  const localWorkspace = localStorage.getItem('workspace');

  if (!workspaces) {
    dataProvider.getWorkspaces(dataProvider).then((dataWorkspaces) => {
      if (dataWorkspaces?.length > 0) {
        setWorkspaces(dataWorkspaces);
        if (!localWorkspace) {
          setWorkspace(dataWorkspaces?.[0]);
          const workspace = dataWorkspaces?.[0];
          localStorage.setItem('workspace', workspace?.originId);
        } else {
          const newWorkspace = dataWorkspaces?.find((workspace) => workspace?.originId == localWorkspace) ?? dataWorkspaces?.[0];
          setWorkspace(newWorkspace);
        }
        refresh();
      }
    });
  }

  const changeWorkspace = (workspace) => {
    setWorkspace(workspace);
    const is_owner = localStorage.getItem('is_owner');
    localStorage.setItem('workspace', workspace?.originId);
    if (!is_owner) {
      localStorage.setItem('allowHeatMap', workspace?.allowHeatMap);
      localStorage.setItem('allowWalkLine', workspace?.allowWalkLine);
      localStorage.setItem('hasAdminRights', workspace?.hasAdminRights);
    }

    window.location.href = "/";
  }

  return (
    <Dropdown>
      <Dropdown.Toggle style={{background: "inherit", border: "0px"}}>
        {workspace?.name ? workspace?.name : ''}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{maxHeight: "200px", overflowY: "scroll"}}>
        {workspaces?.map((workspace, index) => (
          <Dropdown.Item key={index} onClick={() => changeWorkspace(workspace)}>
            {workspace?.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
};

const AppBar = ({ classes, userMenu, ...props }) => {
  const authProvider = useAuthProvider();

  return (
    <BaseAppBar userMenu={userMenu ?? !!authProvider} {...props}>
      <Box
        component="img"
        sx={{
          width: 102,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
        }}
        alt="Your logo."
        src={Logo}
      />
      <Typography
        variant="h6"
        color="inherit"
        className={props.title}
        id="react-admin-title"
      />
      <Box component="span" sx={{ flex: 1 }} />
      <SettingsButton />

    </BaseAppBar>
  );
};

export default AppBar;
