import { defaultTheme } from 'react-admin';

const theme = {
  ...defaultTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#180d8c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F56B5F',
      contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
};

export default theme;
