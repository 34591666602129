import {
  Edit,
  TabbedForm,
  ReferenceManyField,
  Datagrid,
  useRecordContext,
  DateField,
  useTranslate,
  useRefresh,
  useNotify,
  useDataProvider,
  Confirm,
} from 'react-admin';
import OpeningHourForm from '../../Common/Opening_hours/Form';
import { EditProps } from 'ra-ui-materialui/src/types';
import PeriodForm from "../../Common/Periods/Form";
import * as React from "react";
import {useState} from "react";
import {useMutation} from "react-query";
import {IconButton} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const CancelPeriodButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.cancelPeriod(record, dataProvider).then(() => {
      refresh();
      notify('app.form.opening_hours.tab.periods.actions.cancelled');
    }).catch(() => {
      notify('Error', {
        type: 'error',
        messageArgs: { smart_count: 1 },
      });
    })
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };
  let modalContent = translate('app.form.opening_hours.tab.periods.actions.modal.content');

  return (
    <>
      <IconButton
        aria-label="Example"
        onClick={handleClick}
        disabled={isLoading}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={'app.form.opening_hours.tab.periods.actions.modal.title'}
        content={modalContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const EditTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const titleText = record ? record.code ?? undefined : undefined;

  return (
    <span>
      {translate('app.show.locations.tabs.opening_hours.actions.edit')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const Periods = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField reference={`admin/opening_hours/${record?.originId}/periods`} target="openingHourId" label={false}>
      <Datagrid>
        <DateField locales={'en-GB'} source="startDate" />
        <DateField locales={'en-GB'} source="endDate" />
        <CancelPeriodButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

const EditOpeningHour = (props: EditProps) => {

  return (
    <Edit
      {...props}
      title={<EditTitle />}
      actions={false}
      mutationMode={'pessimistic'}
    >
      <TabbedForm toolbar={false}>
        <TabbedForm.Tab label={'app.form.opening_hours.tab.hour_price'}>
          <OpeningHourForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'app.form.opening_hours.tab.active_period'}>
          <PeriodForm />
          <div style={{margin: '10px'}}></div>
          <Periods />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default EditOpeningHour;
