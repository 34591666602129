import {
  Edit,
} from 'react-admin';
import { EditProps } from 'ra-ui-materialui/src/types';
import AdministratorForm from "../../Common/Administrator/FormAdmin";

const EditWorkspace = (props: EditProps) => {
  return (
    <Edit actions={false} {...props} title={'app.form.administrator.title_edit'} redirect={false} mutationMode={'pessimistic'}>
      <AdministratorForm />
    </Edit>
  );
};

export default EditWorkspace;
