import { Grid, Typography } from '@mui/material';
import {
  NumberInput,
  TextInput,
  TimeInput,
  required,
  useRedirect,
  useTranslate,
  ToolbarProps,
  useNotify,
  Toolbar,
  SaveButton,
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const OpeningHourForm = (props: CreateOpeningHourFormProps) => {
  const { isCreate } = props;
  const translate = useTranslate();

  const CustomToolbar = (props: ToolbarProps) => {
    const { reset } = useFormContext();
    const notify = useNotify();
    const redirect = useRedirect();

    return (
      <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <SaveButton
          type="button"
          mutationOptions={{
            onSuccess: (data) => {
              reset();
              window.scrollTo(0, 0);
              notify('app.form.opening_hours.create.success', {
                type: 'success',
                messageArgs: { smart_count: 1 },
              });
              redirect('/admin/opening_hours/' + encodeURIComponent(data.id));
            },
          }}
        />
      </Toolbar>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {isCreate
            ? translate('app.show.locations.tabs.opening_hours.actions.create')
            : translate('app.show.locations.tabs.opening_hours.actions.edit')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.name')}*
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        {isCreate ? (
          <TextInput
            source={'name'}
            label={'app.form.opening_hours.general.name'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'name'}
          />
        ) : (
          <TextInput
            source={'name'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.general.name'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.tab.weekday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.tab.start_time')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.tab.end_time')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.monday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'mondayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'mondayStartTime'}
          />
        ) : (
          <TimeInput
            source={'mondayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'mondayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'mondayEndTime'}
          />
        ) : (
          <TimeInput
            source={'mondayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.tuesday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'tuesdayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'tuesdayStartTime'}
          />
        ) : (
          <TimeInput
            source={'tuesdayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'tuesdayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'tuesdayEndTime'}
          />
        ) : (
          <TimeInput
            source={'tuesdayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.wednesday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'wednesdayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'wednesdayStartTime'}
          />
        ) : (
          <TimeInput
            source={'wednesdayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'wednesdayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'wednesdayEndTime'}
          />
        ) : (
          <TimeInput
            source={'wednesdayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.thursday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'thursdayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'thursdayStartTime'}
          />
        ) : (
          <TimeInput
            source={'thursdayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'thursdayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'thursdayEndTime'}
          />
        ) : (
          <TimeInput
            source={'thursdayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.friday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'fridayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'fridayStartTime'}
          />
        ) : (
          <TimeInput
            source={'fridayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'fridayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'fridayEndTime'}
          />
        ) : (
          <TimeInput
            source={'fridayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.saturday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'saturdayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'saturdayStartTime'}
          />
        ) : (
          <TimeInput
            source={'saturdayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'saturdayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'saturdayEndTime'}
          />
        ) : (
          <TimeInput
            source={'saturdayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.sunday')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'sundayStartTime'}
            label={'app.form.opening_hours.tab.start_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'sundayStartTime'}
          />
        ) : (
          <TimeInput
            source={'sundayStartTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.start_time'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {isCreate ? (
          <TimeInput
            source={'sundayEndTime'}
            label={'app.form.opening_hours.tab.end_time'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'sundayEndTime'}
          />
        ) : (
          <TimeInput
            source={'sundayEndTime'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.tab.end_time'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={12} lg={12} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.tab.pricing')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.price_per_hour')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        {isCreate ? (
          <NumberInput
            source={'pricePerHour'}
            label={'app.form.opening_hours.general.price_per_hour'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'pricePerHour'}
          />
        ) : (
          <NumberInput
            source={'pricePerHour'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.general.price_per_hour'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.price_per_4_hours')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        {isCreate ? (
          <NumberInput
            source={'pricePer4Hours'}
            label={'app.form.opening_hours.general.price_per_4_hours'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'pricePer4Hours'}
          />
        ) : (
          <NumberInput
            source={'pricePer4Hours'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.general.price_per_4_hours'}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">
          {translate('app.form.opening_hours.general.price_per_day')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        {isCreate ? (
          <NumberInput
            source={'pricePerDay'}
            label={'app.form.opening_hours.general.price_per_day'}
            validate={[required()]}
            sx={{ width: '100%' }}
            name={'pricePerDay'}
          />
        ) : (
          <NumberInput
            source={'pricePerDay'}
            validate={[required()]}
            sx={{ width: '100%' }}
            label={'app.form.opening_hours.general.price_per_day'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <CustomToolbar />
      </Grid>
    </Grid>
  );
};

export default OpeningHourForm;

export interface CreateOpeningHourFormProps {
  isCreate?: boolean;
}
