import nl from 'ra-language-dutch';
import { merge } from 'lodash';

const dutchMessages = {
  'ra-supabase': {
    auth: {
      sign_in_with: 'Inloggen met %{provider}',
    },
  },
  ra: {
    page: {
      empty: 'Geen resultaten gevonden',
    },
  },
  app: {
    menu: {
      locations: 'Strollo punten (Locaties)',
      reservations: 'Reservaties',
      media: 'Media',
      strollers: "Buggy's",
      beacon: 'Beacon',
      geofence: 'Geofence',
      payments: 'Payments',
      workspaces: 'Workspaces',
      dashboard: 'Dashboard Platform',
      administrator: 'Administrators',
    },
    edit: {
      locations: {
        title: 'Locatie bewerken',
      },
      strollers: {
        title: 'Buggy bewerken',
      },
      payments: {
        title: 'Edit payment',
      },
      beaconGroup: {
        title: 'Edit beacon group',
      },
      geofence: {
        title: 'Edit geofence',
      }
    },
    common: {
      strollers: {
        status: {
          available: 'Beschikbaar',
          defective: 'Defect',
          maintenance: 'In onderhoud',
          not_returned: 'Niet teruggebracht',
          checked_in: 'Ontgrendeld',
          pause: 'Gepauzeerd',
        },
      },
      payments: {
        status: {
          0: 'Unknown',
          1: 'Pending',
          2: 'Paid',
          3: 'Cancelled',
          4: 'Failed',
          5: 'Expired',
        },
      },
    },
    show: {
      strollers: {
        title: 'Buggy details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status bericht',
        location: 'Locatie',
        device_ios_id: 'Device iOS ID',
        device_android_id: 'Device Android ID',
        device_password: 'Device password',
        url: 'QR Code link',
        tabs: {
          state: {
            label: 'State',
            reported: 'Reported',
            state: 'State',
            latitude: 'Latitude',
            longitude: 'Longitude',
          },
          maintenance_history: {
            label: 'Maintenance history',
            unlock_time: 'Unlock time',
            baby_carriage_clean: 'Baby carriage clean?',
            something_broken: 'Something broken?',
            complaints: 'Complaints',
            pictures: 'Pictures',
          },
        },
      },
      workspaces: {
        title: 'Workspaces details',
        code: 'Code',
        status: 'Status',
        status_message: 'Status message',
        location: 'Location',
        url: 'QR Code link',
        label: 'Reservations',
        table: {
          name: 'Name',
          action: 'Action',
          description: 'Description',
          email: 'Email',
          phone: 'Phone',
        },
        actions: {
          cancel: 'Cancel workspace',
          create: 'Create workspace',
          cancelled: 'Cancelled',
          modal: {
            title: 'Cancel workspace',
            content: `Are you sure you want to cancel this workspace?`,
          },
        },
      },
      locations: {
        title: 'Locatie details',
        tabs: {
          details: {
            label: 'Details',
            total_strollers: "Totaal aantal buggy's",
            stroller_reservation_pool: "Max. aantal te reserveren buggy's",
            number_of_strollers_available_for_registration:
              "Huidig aantal beschikbare buggy's voor registratie",
            number_of_locked_strollers_today:
              "Huidig aantal vergrendelde buggy's",
            number_of_available_strollers:
              "Aantal beschikbare buggy's",
            address: 'Locatie Strollo punt',
            translations: {
              name: 'Naam',
              description: 'Beschrijving',
              intro: 'Intro',
              customer_name: 'Klant naam',
              target_group_info: 'Voor wie?',
              opening_hours_info: 'Openingsuren',
              accessibility_info: 'Beschikbaarheid',
              additional_info: 'Nog vragen?',
              rental_process_info: 'Hoe werkt het?',
              contact_info: 'Contact',
              opening_hours: 'Openingsuren',
              closing_hours_info: 'Sluitingsuur Strollo punt',
              price_info: 'Prijs',
            },
          },
          strollers: {
            label: "Buggy's",
            title: " - Buggy's",
            table: {
              code: 'Code',
              status: 'Status',
              status_message: 'Status bericht',
            },
          },
          reservations: {
            label: 'Reservaties',
            title: ' - Reservaties',
            table: {
              name: 'Door',
              date: 'Datum',
              status: 'Status',
              email: 'Email',
              phone: 'Telefoon',
              strollers: 'Gebruikte buggy(s)',
            },
            actions: {
              cancel: 'Reservatie annuleren',
              cancelled: 'Geannuleerd',
              modal: {
                title: 'Reservatie annuleren',
                content: `Weet je zeker dat de reservatie van %{person} op %{date} wilt annuleren?`,
              },
            },
          },
          coupons: {
            label: 'Coupons',
            title: ' - Coupons',
            table: {
              coupon_code: 'Coupon code',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel coupon',
              create: 'Add coupon code',
              edit: 'Edit coupon code',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel coupon',
                content: `Are you sure you want to cancel this coupon?`,
              },
            },
          },
          opening_hours: {
            label: 'Opening hours & pricing',
            title: ' - Opening hours & pricing',
            table: {
              name: 'Name',
              description: 'Description',
              discount: 'Discount',
              start_date: 'Start date',
              end_date: 'End date',
              action: 'Action',
            },
            actions: {
              cancel: 'Cancel opening hours & pricing',
              create: 'Add opening hours & pricing',
              edit: 'Edit opening hours & pricing',
              cancelled: 'Cancelled',
              modal: {
                title: 'Cancel opening hours & pricing',
                content: `Are you sure you want to cancel this opening hours & pricing?`,
              },
            },
          },
          walk_lines: {
            label: 'Walk lines',
          },
          heatmap: {
            label: 'Heatmap',
          }
        },
      },
    },
    form: {
      strollers: {
        create: {
          success: 'Buggy succesvol aangemaakt',
        },
        general: {
          labels: {
            stroller_info: 'Buggy info',
            stroller_status: 'Buggy status',
          },
          code: 'Code',
          url: 'QR Code link',
          device_ios_id: 'Device iOS ID',
          device_android_id: 'Device Android ID',
          device_password: 'Device password',
          status: {
            label: 'Status',
          },
          status_message: 'Status bericht',
          location: 'Gekoppeld aan Strollo punt (optioneel)',
        },
      },
      locations: {
        general: {
          labels: {
            image: 'Afbeelding',
          },
          address: 'Locatie Strollo punt',
          label: 'Algemeen',
          stroller_reservation_pool: 'Buggy reservatie pool',
          publish_status: {
            label: 'Status',
            published: 'Gepubliceerd',
            draft: 'Concept',
            offline: 'Offline',
          },
          image: 'Afbeelding',
          mollie_id: 'Mollie id',
        },
        notes: {
          label: 'Notities',
          alert: {
            title: 'Opgelet!',
            description: 'Deze gegevens zijn enkel voor intern gebruik.',
          },
        },
        translations: {
          label: 'Vertalingen',
          name: 'Naam',
          description: 'Beschrijving',
          intro: 'Intro',
          customer_name: 'Klant naam',
          target_group_info: 'Voor wie?',
          opening_hours_info: 'Openingsuren',
          accessibility_info: 'Bereikbaarheid',
          additional_info: 'Nog vragen?',
          rental_process_info: 'Hoe werkt het?',
          contact_info: 'Contact',
          opening_hours: 'Openingsuren',
          closing_hours_info: 'Sluitingsuur Strollo punt',
          price_info: 'Prijs',
        },
        create: {
          success: 'Location saved successfully',
        },
      },
      coupons: {
        create: {
          success: 'Coupon saved successfully',
        },
        edit: {
          success: 'Coupon updated successfully',
        },
        general: {
          code: 'Discount code',
          description: 'Description',
          discount: 'Discount',
          start_date: 'Start date',
          end_date: 'End date',
          action: 'Action',
          status_message: 'Status message',
        },
      },
      opening_hours: {
        create: {
          success: 'Opening hours saved successfully',
        },
        edit: {
          success: 'Opening hours saved successfully',
        },
        tab : {
          active_period: 'Active period',
          weekday: 'Weekday',
          pricing: 'Pricing',
          start_time: 'Start time',
          end_time: 'End time',
          hour_price: 'Openings hours & Pricing',
          periods: {
            title: 'Periods',
            actions: {
              cancelled: 'Cancelled',
              created: 'Period added successfully',
              modal: {
                title: 'Cancel period',
                content: `Are you sure you want to cancel this period?`,
              }
            }
          },
        },
        general: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          name: 'Name',
          price_per_hour: 'Price per hour',
          price_per_additional_hour: 'Price per additional hour',
          price_per_day: 'Price per day',
          price_per_4_hours: 'Price per 4 hours',
        },
      },
      periods: {
        create: {
          success: 'Period created successfully',
        },
        edit: {
          success: 'Period created successfully',
        },
        general: {
          start_date: 'Start date',
          end_date: 'End date',
        },
      },
      payments: {
        edit: {
          success: 'Payment saved successfully',
        },
        general: {
          labels: {
            payment_info: 'Payment info',
            payment_status: 'Payment status',
          },
          user: 'User',
          stroller: 'Stroller',
          amount: 'Amount',
          paid: 'Paid',
          created_at: 'Created at',
          status: {
            label: 'Status',
          },
          status_message: 'Status message',
          location: 'Linked to Strollo point (optional)',
        },
      },
      beaconGroup: {
        beaconGroup: {
          title: 'Beacon group',
          name: 'Name',
          workspace: 'Workspace',
        },
        beacon: {
          title: 'Beacons',
          name: 'Name',
          uuid: 'Beacon UUID',
        },
      },
      geofence: {
        title: 'Geofence',
        name: 'Name',
        workspace: 'Workspace',
        latitude: 'Latitude',
        longitude: 'Longitude',
        add_new_geofence: 'Add new geofence',
      },
      workspaces: {
        create: {
          success: 'Workspace saved successfully',
        },
        tab: {
          title: 'Create Workspace',
          workspace: 'Workspace',
          administrator: 'Administrator',
        },
        general: {
          labels: {
            workspace_info: 'Workspace info',
            stroller_status: 'Stroller status',
          },
          name: 'Name',
          description: 'Description',
          location: 'Location',
          max_location: 'Max Location',
          admin_rights: 'Admin rights',
          heatmap: 'Heatmap',
          users: 'Users',
          walking_lines: 'Walking lines',
        },
      },
      administrator: {
        title: "Administrator",
        edit: {
          success: 'Administrator saved successfully',
        },
        general: {
          labels: {
            administrator_info: 'Administrator info',
          },
          name: 'Name',
          surname: 'Surname',
          email: 'E-mail',
        },
      },
    },
    list: {
      media: {
        image: 'Afbeelding',
        title: 'Naam',
        actions: {
          create: 'Nieuwe afbeelding toevoegen',
        },
      },
      dashboard: {
        title: 'Dashboard',
        error: 'Error',
        error_des: 'You are not having access to this site. Please contact the admin for support.',
        users_nb: 'Number of users',
        reservations_nb: 'Number of reservations',
        succesfull_payments_nb: 'Number of successful payments',
        locations_nb: 'Number of locations',
        cancellations_nb: 'Number of cancellations',
        open_payments_nb: 'Number of open payments',
        filter: {
          last_week: 'Last week',
          last_month: 'Last month',
          last_year: 'Last year',
          last_three_months: 'Last three months',
        }
      },
      strollers: {
        code: 'Code',
        status: 'Status',
        status_message: 'Status bericht',
        location: 'Locatie',
        actions: {
          create: 'Nieuwe buggy toevoegen',
        },
      },
      locations: {
        name: 'Klant (Strollo punt)',
        translations: 'Vertalingen',
        image: 'Afbeelding',
        available_strollers: '# Beschikbaar',
        total_strollers: '# Totaal',
        actions: {
          create: 'Nieuwe B2B klant aanmaken',
        },
      },
      payments: {
        transaction_id: 'Transaction ID',
        date: 'Date',
        status: 'Status',
        location: 'Location',
        amount: 'Amount',
        paid: 'Paid',
        user: 'User',
        actions: {
          create: 'Add new stroller',
        }
      },
      beaconGroup: {
        name: 'Name',
        actions: {
          create: 'Create new beacon group',
        },
      },
      geofence: {
        geofence: 'Geofence',
        name: 'Name',
        actions: {
          create: 'Create new geofence',
        },
      }
    },
  },
};

const messages = merge(nl, dutchMessages);

export default messages;
