import { Grid, Typography } from '@mui/material';
import {
  DateInput,
  SaveButton,
  SimpleForm,
  TextInput,
  NumberInput,
  Toolbar,
  required,
  minLength,
  maxLength,
  ToolbarProps,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = (props: ToolbarProps) => {
  const { reset } = useFormContext();
  const notify = useNotify();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            window.scrollTo(0, 0);
            notify('app.form.coupons.create.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

const CouponForm = (props: CreateCouponFormProps) => {
  const { isCreate } = props;
  const translate = useTranslate();

  return (
    <SimpleForm toolbar={<CustomToolbar/>}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isCreate ? (
            <Typography variant="h6">
              {translate('app.show.locations.tabs.coupons.actions.create')}
            </Typography>
          ) : (
            <Typography variant="h6">
              {translate('app.show.locations.tabs.coupons.actions.edit')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.coupons.general.code')}*
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isCreate ? (
            <TextInput
              source={'code'}
              label={'app.form.coupons.general.code'}
              validate={[required(), minLength(2), maxLength(32)]}
              sx={{ width: '100%' }}
              name={'code'}
            />
          ) : (
            <TextInput
              source={'code'}
              validate={[required(), minLength(2), maxLength(32)]}
              sx={{ width: '100%' }}
              label={'app.form.coupons.general.code'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.coupons.general.description')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isCreate ? (
            <TextInput
              source={'description'}
              label={'app.form.coupons.general.description'}
              validate={[maxLength(255)]}
              sx={{ width: '100%' }}
              name={'description'}
            />
          ) : (
            <TextInput
              source={'description'}
              sx={{ width: '100%' }}
              validate={[maxLength(255)]}
              label={'app.form.coupons.general.description'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.coupons.general.discount')} % *
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isCreate ? (
            <NumberInput
              source={'discountPercentage'}
              label={'app.form.coupons.general.discount'}
              validate={[required(), minLength(1), maxLength(2)]}
              sx={{ width: '100%' }}
              name={'discountPercentage'}
            />
          ) : (
            <NumberInput
              source={'discountPercentage'}
              sx={{ width: '100%' }}
              validate={[required(), minLength(1), maxLength(2)]}
              label={'app.form.coupons.general.discount'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.coupons.general.start_date')}*
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isCreate ? (
            <DateInput
              source={'availableAt'}
              label={'app.form.coupons.general.start_date'}
              validate={[required()]}
              sx={{ width: '100%' }}
              name={'availableAt'}
            />
          ) : (
            <DateInput
              source={'availableAt'}
              validate={[required()]}
              sx={{ width: '100%' }}
              label={'app.form.coupons.general.start_date'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6">
            {translate('app.form.coupons.general.end_date')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isCreate ? (
            <DateInput
              source={'expiresAt'}
              label={'app.form.coupons.general.end_date'}
              // validate={[required(), minLength(6), maxLength(6)]}
              sx={{ width: '100%' }}
              name={'expiresAt'}
            />
          ) : (
            <DateInput
              source={'expiresAt'}
              sx={{ width: '100%' }}
              label={'app.form.coupons.general.end_date'}
            />
          )}
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default CouponForm;

export interface CreateCouponFormProps {
  isCreate?: boolean;
}
