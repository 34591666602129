import { Create } from 'react-admin';
import CouponForm from '../../Common/Coupons/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CreateCoupon = (props: CreateProps) => {
  return (
    <Create {...props} title={'app.show.locations.tabs.coupons.actions.create'} redirect={false}>
      <CouponForm isCreate />
    </Create>
  );
};

export default CreateCoupon;
