import { Edit, useRecordContext, useTranslate } from 'react-admin';

import CouponForm from '../../Common/Coupons/Form';
import { EditProps } from 'ra-ui-materialui/src/types';

const EditTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const titleText = record ? record.code ?? undefined : undefined;

  return (
    <span>
      {translate('app.show.locations.tabs.coupons.actions.edit')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const EditCoupon = (props: EditProps) => {
  return (
    <Edit
      {...props}
      title={<EditTitle />}
      actions={false}
      mutationMode={'pessimistic'}
    >
      <CouponForm />
    </Edit>
  );
};

export default EditCoupon;
