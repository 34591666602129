export const validUpdateFields = [
  'id',
  'location',
  'name',
  'mondayStartTime',
  'mondayEndTime',
  'tuesdayStartTime',
  'tuesdayEndTime',
  'wednesdayStartTime',
  'wednesdayEndTime',
  'thursdayStartTime',
  'thursdayEndTime',
  'fridayStartTime',
  'fridayEndTime',
  'saturdayStartTime',
  'saturdayEndTime',
  'sundayStartTime',
  'sundayEndTime',
  'pricePerHour',
  'pricePerDay',
  'pricePer4Hours',
];
