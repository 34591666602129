import {
  Edit,
  TabbedForm,
} from 'react-admin';
import { EditProps } from 'ra-ui-materialui/src/types';
import WorkspaceForm from "../../Common/Workspaces/Form";
import AdministratorForm from "../../Common/Administrator/Form";

const EditWorkspace = (props: EditProps) => {
  return (
    <Edit actions={false} {...props} title={'app.form.workspaces.tab.title_edit'} redirect={false} mutationMode={'pessimistic'}>
      <TabbedForm>
        <TabbedForm.Tab label={'app.form.workspaces.tab.workspace'}>
          <WorkspaceForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'app.form.workspaces.tab.administrator'}>
          <AdministratorForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default EditWorkspace;
