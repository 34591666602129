import {Create, SaveButton, Toolbar, ToolbarProps, useNotify, useRedirect} from 'react-admin';
import WorkspaceForm from '../../Common/Workspaces/Form';
import AdministratorForm from '../../Common/Administrator/Form';
import { CreateProps } from 'ra-ui-materialui/src/types';
import {
  TabbedForm
} from 'react-admin';

const CustomToolbar = (props: ToolbarProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: (data) => {
            notify('app.form.strollers.create.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
            redirect('/admin/workspaces/' + encodeURIComponent(data.id));
          },

        }}
      />
    </Toolbar>
  );
};

const CreateWorkspace = (props: CreateProps) => {
  return (
    <Create {...props} title={'app.form.workspaces.tab.title'} redirect={'edit'}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <TabbedForm.Tab label={'app.form.workspaces.tab.workspace'}>
          <WorkspaceForm isCreate />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'app.form.workspaces.tab.administrator'}>
          <AdministratorForm isCreate />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default CreateWorkspace;
