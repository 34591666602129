import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import TranslatedField from '../../../Common/TranslatedField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ContentAdd from '@mui/icons-material/Add';
import * as React from 'react';

const CreateRelatedStrollerButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      startIcon={<ContentAdd />}
      component={Link}
      to={{
        pathname: '/admin/strollers/create',
      }}
      state={{ record: { 'location.@id': record.id } }}
    >
      {translate('app.list.strollers.actions.create')}
    </Button>
  );
};

const LocationStrollers = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <>
      <List
        resource={'admin/strollers'}
        filter={{ location: record.id }}
        exporter={false}
        actions={false}
        title={<>{translate('app.show.locations.tabs.strollers.title')}</>}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={'show'}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={'code'}
            sortable={false}
            label={'app.show.locations.tabs.strollers.table.code'}
          />
          <TranslatedField
            source={'status'}
            label={'app.show.locations.tabs.strollers.table.status'}
            sortable={false}
            prefix={'app.common.strollers.status'}
          />
          <TextField
            source={'statusMessage'}
            sortable={false}
            label={'app.show.locations.tabs.strollers.table.status_message'}
          />
        </Datagrid>
      </List>
      <CreateRelatedStrollerButton />
    </>
  );
};

export default LocationStrollers;
