export const validUpdateFields = [
  'id',
  'name',
  'active',
  'description',
  'maxLocations',
  'hasAdminRights',
  'allowHeatMap',
  'allowWalkLine',
  'locationIds',
  'userIds'
];
