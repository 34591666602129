import { ImageField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { SimpleShowLayoutProps } from 'ra-ui-materialui/src/detail/SimpleShowLayout';

const ShowMedia = (props: SimpleShowLayoutProps) => {
  return (
    <Show>
      <SimpleShowLayout {...props}>
        <TextField source={'title'} variant={'h5'} />
        <ImageField source={'contentUrl'} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowMedia;
