import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  WrapperField,
  TopToolbar, DeleteWithConfirmButton, EditButton,ArrayField, SingleFieldList, ChipField
} from 'react-admin';
import {Button} from '@mui/material';
import * as React from "react";
import ContentAdd from "@mui/icons-material/Add";
import {Link} from "react-router-dom";
import { ListProps } from 'ra-ui-materialui/src/list/List';

const AdministratorList = (props: ListProps) => {
  const translate = useTranslate();

  return (
    <>
      <TopToolbar>
        <Button
          startIcon={<ContentAdd />}
          component={Link}
          to={{
            pathname: '/admin/administrator/create',
          }}
        >
          {translate('app.show.administrators.actions.create')}
        </Button>
      </TopToolbar>
      <List
        {...props}
        actions={false}
      >
        <Datagrid
          bulkActionButtons={false}
          size={'medium'}
          sx={{
            '& .RaDatagrid-headerCell': {
              backgroundColor: '#eee',
              fontWeight: 'bold',
              fontSize: '1rem',
            },
          }}
        >
          <TextField
            source={'firstName'}
            label={'app.show.administrators.table.name'}
          />
          <TextField
            source={'lastName'}
            label={'app.show.administrators.table.surname'}
          />
          <ArrayField source="workspaceUsers">
            <SingleFieldList linkType={false}>
              <ChipField source="workspace[name]" size="small" />
            </SingleFieldList>
          </ArrayField>
          <WrapperField label={'app.show.administrators.table.action'}>
            <DeleteWithConfirmButton />
            <EditButton />
          </WrapperField>
        </Datagrid>
      </List>
    </>
  );
};

export default AdministratorList;
