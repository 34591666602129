import * as React from 'react';
import { ReactNode } from 'react';

import { useRedirectIfAuthenticated } from 'ra-supabase-core';
import { Provider } from '@supabase/supabase-js';
import { Divider, Stack } from '@mui/material';
import { GoogleButton, LoginForm } from 'ra-supabase';
import { AuthLayout } from './Auth/AuthLayout';

import theme from './Theme';

export const LoginPage = (props: LoginPageProps) => {
  useRedirectIfAuthenticated();
  const {
    children,
    disableEmailPassword = false,
    disableForgotPassword = false,
    providers = [],
  } = props;

  return (
    <AuthLayout theme={theme}>
      {children ?? (
        <>
          {disableEmailPassword ? null : (
            <LoginForm disableForgotPassword={disableForgotPassword} />
          )}
          {disableEmailPassword || providers.length === 0 ? null : <Divider />}
          {providers && providers.length > 0 ? (
            <>
              <Stack gap={1} padding={1}>
                {providers.includes('google') ? <GoogleButton /> : null}
              </Stack>
            </>
          ) : null}
        </>
      )}
    </AuthLayout>
  );
};

export type LoginPageProps = {
  children?: ReactNode;
  disableEmailPassword?: boolean;
  disableForgotPassword?: boolean;
  providers?: Provider[];
};
