import { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/Stroller';

export const strollersLifecycleCallbacks = {
  resource: 'admin/strollers',
  afterRead: async (data) => {
    if (get(data, 'code', false)) {
      set(
        data,
        'url',
        `${process.env.REACT_APP_FRONTEND_ENTRYPOINT}/${get(data, 'code')}`
      );
    }

    return data;
  },
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);

    if (get(data.location, '@id', false)) {
      set(data, 'location', get(data.location, '@id'));
    } else {
      data.location = null;
    }

    return data;
  },
};
