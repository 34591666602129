export const validUpdateFields = [
  'id',
  'location',
  'user',
  'amount',
  'created_at',
  'stroller',
  'paid',
  'status',
];
