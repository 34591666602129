import _, { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/Geofence';

export const geofenceLifecycleCallbacks = {
  resource: 'admin/geofence',
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
  
    if (get(data.workspace, '@id', false)) {
      set(data, 'workspace', get(data.workspace, '@id'));
    } else {
      data.workspace = null;
    }

    if(data.geofenceCoordinates && data.geofenceCoordinates.length > 0) {
      data.geofenceCoordinates = data.geofenceCoordinates.map((coordinate) => {
        if(!get(coordinate, '@id', false)) {
          return {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude
          }
        }
        
        return coordinate;
      });
    }

    return data;
  }
};
