import {
  Create,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRedirect
} from 'react-admin';
import AdministratorForm from '../../Common/Administrator/FormAdmin';
import { CreateProps } from 'ra-ui-materialui/src/types';

const CustomToolbar = (props: ToolbarProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: (data) => {
            notify('app.form.strollers.create.success', {
              type: 'success',
              messageArgs: { smart_count: 1 },
            });
            redirect('/admin/workspaces/' + encodeURIComponent(data.id));
          },

        }}
      />
    </Toolbar>
  );
};

const CreateWorkspace = (props: CreateProps) => {
  return (
    <Create {...props} title={'app.form.administrator.title_create'}>
      <AdministratorForm isCreate />
    </Create>
  );
};

export default CreateWorkspace;
