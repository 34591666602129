import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  TopToolbar,
  useLocaleState,
  WrapperField,
  useDataProvider,
  useNotify, useTranslate, useRefresh
} from 'react-admin';
import { ListProps } from 'ra-ui-materialui/src/list/List';
import TranslatedField from '../../Common/TranslatedField';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Button from '@mui/material/Button';

const StrollerList = (props: ListProps) => {
  const [locale] = useLocaleState();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleOnChange = (e: any) => {
    const file = e.target.files[0];
    dataProvider.importStroller(file, dataProvider).then((data: any) => {
      if (data.code && data.code == 200) {
        notify('app.list.strollers.actions.successful_import', {
          type: 'success',
          messageArgs: { smart_count: 1 },
        });
      } else {
        notify(data.detail, {
          type: 'error',
          messageArgs: { smart_count: 1 },
        });
      }

      refresh();
    }).catch(() => {
      notify('Error', {
        type: 'error',
        messageArgs: { smart_count: 1 },
      });
    });
  };

  const ListActions = () => (
    <TopToolbar>
      <Button variant="text" style={{padding: "0px"}}>
        <label onChange={handleOnChange} htmlFor="formId">
          <input
            type={"file"}
            accept={".csv"}
            id="formId" hidden
          />
          <ImportExportIcon />{translate('app.list.strollers.actions.import')}
        </label>
      </Button>
      <CreateButton label={'app.list.strollers.actions.create'} />
    </TopToolbar>
  );

  return (
    <List
      {...props}
      actions={<ListActions />}
      sort={{ field: 'code', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={'show'}
        size={'medium'}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <TextField
          source={`code`}
          label={'app.list.strollers.code'}
          sortable={true}
        />
        <TextField
          source={`location.translations.${locale}.name`}
          label={'app.list.strollers.location'}
          sortable={false}
        />
        <TranslatedField
          source={'status'}
          label={'app.list.strollers.status'}
          sortable={true}
          prefix={'app.common.strollers.status'}
        />
        <TextField
          source={`statusMessage`}
          label={'app.list.strollers.status_message'}
          sortable={false}
        />
        <WrapperField>
          <ShowButton />
          <EditButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default StrollerList;
