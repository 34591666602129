import { Edit, useRecordContext, useTranslate } from 'react-admin';

import StrollerForm from '../../Common/Strollers/Form';
import { EditProps } from 'ra-ui-materialui/src/types';

const EditTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const titleText = record ? record.code ?? undefined : undefined;

  return (
    <span>
      {translate('app.edit.strollers.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const EditStroller = (props: EditProps) => {
  return (
    <Edit
      {...props}
      title={<EditTitle />}
      redirect={'show'}
      mutationMode={'pessimistic'}
    >
      <StrollerForm />
    </Edit>
  );
};

export default EditStroller;
