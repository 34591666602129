import {
  useDataProvider,
  useTranslate,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Box, Typography} from '@mui/material';
import { ListProps } from 'ra-ui-materialui/src/list/List';
import * as React from "react";
import {useState, useEffect} from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const DashboardList = (props: ListProps) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [info, setInfor] = useState(null);
  const [filter, setFilter] = useState('last_week');
  const workspaceId = localStorage.getItem('workspace');

  useEffect(() => {
    dataProvider.getUser(dataProvider, workspaceId, filter).then((data: any) => {
      setInfor(JSON.parse(data?.data?.dashboardInfor));
    });
  }, [workspaceId, filter]);
  const handleChange = (e: SelectChangeEvent) => {
    setFilter(e.target.value);
  }

  return (
    <CardContent>
      {workspaceId ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {translate('app.list.dashboard.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 300 }}>
              <Select
                value={filter}
                onChange={handleChange}
              >
                <MenuItem value={'last_week'}>{translate('app.list.dashboard.filter.last_week')}</MenuItem>
                <MenuItem value={'last_month'}>{translate('app.list.dashboard.filter.last_month')}</MenuItem>
                <MenuItem value={'last_three_months'}>{translate('app.list.dashboard.filter.last_three_months')}</MenuItem>
                <MenuItem value={'last_year'}>{translate('app.list.dashboard.filter.last_year')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {info && Object.entries(info).map((item: any, index:any) => {
            return (
              <Grid item xs={4} key={index}>
                <Box sx={{
                  border: '1px solid',
                  padding: '15px',
                  borderRadius: '10px',
                  textAlign: 'center',
                }}>
                  <Typography variant="h6">
                    {item[0]}
                  </Typography>
                  <Typography variant="h5">
                    {item[1]}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {translate('app.list.dashboard.error')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {translate('app.list.dashboard.error_des')}
            </Typography>
          </Grid>
        </Grid>
      )}

    </CardContent>
  );
};

export default DashboardList;
