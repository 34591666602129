import { Grid, Typography } from '@mui/material';
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
} from 'react-admin';

const WorkspaceForm = (props: CreateWorkspaceFormProps) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6">
          {translate('app.form.workspaces.tab.administrator')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <ReferenceArrayInput
          source="userIds"
          reference={'admin/users'}
          name={'userIds'}
          filter={{ is_admin: true }}
          perPage={300}
        >
          <AutocompleteArrayInput
            sx={{ width: '100%' }}
            label={'app.form.workspaces.general.users'}
            optionText={`email`}
          />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  );
};

export default WorkspaceForm;

export interface CreateWorkspaceFormProps {
  isCreate?: boolean;
}
