import { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/Coupon';

export const couponsLifecycleCallbacks = {
  resource: 'admin/coupons',
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
    if (data.discountPercentage % 1 != 0) {
      throw new Error('Discount percentage must be an integer');
    }
    if (get(data.location, '@id', false)) {
      set(data, 'location', get(data.location, '@id'));
    } else {
      data.location = null;
    }

    return data;
  },
};
