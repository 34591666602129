import _, { get, set } from 'lodash';
import { cleanFields } from '../Model/ApiModelCleaner';
import { validUpdateFields } from '../Model/BeaconGroup';

export const beaconGroupLifecycleCallbacks = {
  resource: 'admin/beacon-group',
  beforeSave: async (data, dataProvider) => {
    data = cleanFields(data, validUpdateFields);
  
    if (get(data.workspace, '@id', false)) {
      set(data, 'workspace', get(data.workspace, '@id'));
    } else {
      data.workspace = null;
    }

    if(data.beacons && data.beacons.length > 0) {
      data.beacons = data.beacons.map((beacon) => {
        if(!get(beacon, '@id', false)) {
          return {
            name: beacon.name,
            uuid: beacon.uuid
          }
        }
        
        return beacon;
      });
    }

    return data;
  }
};
