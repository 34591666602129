import {
  Edit,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import GeofenceForm from '../../Common/Geofence/Form';
import { EditProps } from 'ra-ui-materialui/src/types';

const EditTitle = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const titleText = record?.translations?.name?.[locale] ?? undefined;

  return (
    <span>
      {translate('app.edit.geofence.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const EditGeofence = (props: EditProps) => {
  return (
    <Edit actions={false} {...props} title={<EditTitle />} redirect="edit" mutationMode={'pessimistic'}>
      <GeofenceForm origin="edit" />
    </Edit>
  );
};

export default EditGeofence;
