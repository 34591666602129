import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  WrapperField,
  DeleteWithConfirmButton,
  ArrayField,
} from 'react-admin';
import { ListProps } from 'ra-ui-materialui/src/list/List';

const BeaconGroupList = (props: ListProps) => {
  const ListActions = () => (
    <TopToolbar>
      <CreateButton label={'app.list.beaconGroup.actions.create'} />
    </TopToolbar>
  );

  return (
    <List
      {...props}
      actions={<ListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        size={'medium'}
        sx={{
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
      >
        <TextField
          source={`name`}
          label={'app.list.beaconGroup.name'}
          sortable={false}
        />

        <ArrayField source="beacons" sortable={false}>
          <Datagrid bulkActionButtons={false} size={'small'}>
            <TextField source="name" />
            <TextField source="uuid" />
          </Datagrid>
        </ArrayField>

        <WrapperField>
          <EditButton />
          <DeleteWithConfirmButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default BeaconGroupList;
